<div class="Buchheit-header-search searchBar">
  <hawksearch-search-field style="width: 100%;"></hawksearch-search-field>
  <div class="searchBar-result" [ngClass]="{'active' : searchBarActive}" *ngIf="searchData.length > 0">
    <ng-container *ngIf="searchData.length > 0"> 
      <ul class="searchBar-result-list">
        <li *ngFor="let item of searchData; let i = index;" (click)="goToSearchPage(item.url);">
          <div class="result-logo">
            <img [src]="getImageUrl(commonService.getProductDefaultImage(item,true)?.image, 50, 50, false,commonService.getProductDefaultImage(item,true)?.image_timestamp)" alt="{{commonService.getProductDefaultImage(item,true)?.alt_tag}}" width="50" height="50" (error)="commonService.getNotFoundImage($event,50,50)">
          </div>
          <div class="result-name">
            {{item.Item.name}}
            <span *ngIf="item.hasOwnProperty('itemmerchandisehierarchygroup') && item.itemmerchandisehierarchygroup!=='undefined' && item.itemmerchandisehierarchygroup.length > 0">in {{getTaxonomyName(item?.itemmerchandisehierarchygroup).merchandisehierarchygroupname}}</span>
          </div>
        </li>
      </ul>
      <ng-container *ngIf="inTaxonomy.length>0">
        <div class="searchBar-result-titel">Taxonomy</div>
        <ul class="searchBar-result-list">
          <li *ngFor="let item of inTaxonomy" (click)="goToSearchListPage(item.url, 'MOUSE');">
            <div class="result-name">
              {{item.merchandisehierarchygroupname}}
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</div>
