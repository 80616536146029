<section class="Buchheit-footer-component">
	<div class="container-xl">

		<ul class="Buchheit-footer-component-list">
			<ng-container *ngIf="footerDataJson && footerDataJson.sectionOneAboutGroup">
				<li>
					<a [href]="footerDataJson.sectionOneAboutGroup.url">
						<mat-icon aria-label="icon">{{footerDataJson.sectionOneAboutGroup.icon}}</mat-icon>
						{{footerDataJson.sectionOneAboutGroup.label}}
					</a>
				</li>
			</ng-container>
			<ng-container
				*ngIf="footerDataJson && footerDataJson.sectionOneArray && footerDataJson.sectionOneArray.length > 0">
				<ng-container *ngFor="let section of footerDataJson.sectionOneArray;let i=index;">
					<li *ngIf="section.label !== 'Order Status'">
						<a [href]="section.url">
							<mat-icon aria-label="icon">{{section.icon}}</mat-icon>
							{{section.label}}
						</a>
					</li>
					<li *ngIf="section.label == 'Order Status'" (click)="goOrderStatus(section)">
						<a href="/" (click)="$event.preventDefault()">
							<mat-icon aria-label="icon">{{section.icon}}</mat-icon>
							{{section.label}}
						</a>
					</li>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="footerDataJson && footerDataJson.sectionOneSignUpGroup">
				<li (click)="openSignUp()" class="pointer-cursor">
					<mat-icon aria-label="icon">{{footerDataJson.sectionOneSignUpGroup.icon}}</mat-icon>
					{{footerDataJson.sectionOneSignUpGroup.label}}
				</li>
			</ng-container>
		</ul>
	</div>
</section>

<section class="Buchheit-footer-links"
	*ngIf="footerDataJson && footerDataJson.sectionTwo && (footerDataJson.sectionTwo.customServiceArray.length > 0 || footerDataJson.sectionTwo.aboutUsArray.length > 0 || footerDataJson.sectionTwo.resourcesArray.length > 0 || footerDataJson.sectionTwo.connectGroup.facebookGroup.url !== '' || footerDataJson.sectionTwo.connectGroup.pinterestGroup.url !== '')">
	<div class="container-xl">
		<ul class="Buchheit-footer-links-list">
			<li *ngIf="footerDataJson.sectionTwo.customServiceArray && footerDataJson.sectionTwo.customServiceArray.length > 0"
				(click)="currentLink('custom')" [ngClass]="customSecActive?'active':''">
				<div class="Buchheit-footer-links-list-heading">Customer Service</div>
				<ul class="Buchheit-footer-links-list-urls">
					<li *ngFor="let custom of footerDataJson.sectionTwo.customServiceArray;let i=index;"><a
							[href]="custom.url" id="">{{custom.label}}</a></li>
				</ul>
			</li>
			<li *ngIf="footerDataJson.sectionTwo.aboutUsArray && footerDataJson.sectionTwo.aboutUsArray.length > 0"
				(click)="currentLink('about')" [ngClass]="aboutSecActive?'active':''">
				<div class="Buchheit-footer-links-list-heading">About Us</div>
				<ul class="Buchheit-footer-links-list-urls">
					<li *ngFor="let about of footerDataJson.sectionTwo.aboutUsArray;let i=index;"><a [href]="about.url"
							id="">{{about.label}}</a></li>
				</ul>
			</li>
			<li *ngIf="footerDataJson.sectionTwo.resourcesArray && footerDataJson.sectionTwo.resourcesArray.length > 0"
				(click)="currentLink('resource')" [ngClass]="resourceSecActive?'active':''">
				<div class="Buchheit-footer-links-list-heading">Resources</div>
				<ul class="Buchheit-footer-links-list-urls">
					<li *ngFor="let resource of footerDataJson.sectionTwo.resourcesArray;let i=index;"><a
							[href]="resource.url" id="">{{resource.label}}</a></li>
				</ul>
			</li>

			<li [ngClass]="connectSecActive?'active':''">
				<ng-container
					*ngIf="footerDataJson.sectionTwo.connectGroup && footerDataJson.sectionTwo.connectGroup && (footerDataJson.sectionTwo.connectGroup.facebookGroup.url !== '' && footerDataJson.sectionTwo.connectGroup.pinterestGroup.url !== '')">
					<div class="Buchheit-footer-links-list-heading" (click)="currentLink('connect')">Connect with us</div>
					<ul class="Buchheit-footer-links-list-urls" >
						<li
							*ngIf="footerDataJson.sectionTwo.connectGroup.facebookGroup && footerDataJson.sectionTwo.connectGroup.facebookGroup.url !== ''">
							<em class="icon-facebook"></em>
							<a [href]="footerDataJson.sectionTwo.connectGroup.facebookGroup.url" target="_blank" id=""
								rel="noopener">Facebook </a>
						</li>
						<li
							*ngIf="footerDataJson.sectionTwo.connectGroup.pinterestGroup && footerDataJson.sectionTwo.connectGroup.pinterestGroup.url !== ''">
							<em class="icon-pinterest"></em>
							<a [href]="footerDataJson.sectionTwo.connectGroup.pinterestGroup.url" target="_blank" id=""
								rel="noopener">Pinterest</a>
						</li>
					</ul>
				</ng-container>
				<ng-container
					*ngIf="footerDataJson.sectionTwo.findUsArray && footerDataJson.sectionTwo.findUsArray.length > 0" >
					<div class="d-none d-md-block mt-2">
						<div class="Buchheit-footer-links-list-heading" (click)="currentLink('find')">Find Us</div>
						<ul class="Buchheit-footer-links-list-urls" [ngClass]="findSecActive?'active':''">
							<li *ngFor="let findUs of footerDataJson.sectionTwo.findUsArray;let i=index;">
								<mat-icon color="accent">location_on</mat-icon>
								<a [href]="findUs.url" id="">{{findUs.label}}</a>
							</li>
						</ul>
					</div>
				</ng-container>
			</li>
			<ng-container
				*ngIf="footerDataJson.sectionTwo.findUsArray && footerDataJson.sectionTwo.findUsArray.length > 0"
				(click)="currentLink('find')" >
				<li class="d-md-none" [ngClass]="findSecActive?'active':''">
					<div class="Buchheit-footer-links-list-heading" (click)="currentLink('find')">Find Us</div>
					<ul class="Buchheit-footer-links-list-urls" >
						<li *ngFor="let findUs of footerDataJson.sectionTwo.findUsArray;let i=index;" >
							<mat-icon color="accent">location_on</mat-icon>
							<a [href]="findUs.url" id="">{{findUs.label}}</a>
						</li>
					</ul>
				</li>
			</ng-container>
		</ul>
	</div>
</section>
<section class="Buchheit-footer-brand"
	*ngIf="footerDataJson && footerDataJson.sectionThreeArray && footerDataJson.sectionThreeArray.length > 0">
	<div class="container-xl">
		<ul class="Buchheit-footer-brand-list">
			<li *ngFor="let brand of footerDataJson.sectionThreeArray;let i=index;">
				<a [href]="brand.url" target="_blank" rel="noopener">
					<img height="100" width="100" alt="{{brand.image_name}}"
						[defaultImage]="getImageUrl(brand.image_name,'cms',  true)"
						[lazyLoad]="getImageUrl(brand.image_name,'cms', false)">
				</a>
			</li>
		</ul>
	</div>
</section>
<footer class="Buchheit-footer" *ngIf="footerDataJson && footerDataJson.sectionFour && footerDataJson.sectionFour">
	<div class="container-xl">
		<div class="row align-items-center">
			<div class="col-md-auto order-md-1 ml-auto"
				*ngIf="footerDataJson && footerDataJson.sectionFour && footerDataJson.sectionFour.rightSide && footerDataJson.sectionFour.rightSide.rightSideArray && footerDataJson.sectionFour.rightSide.rightSideArray.length > 0">
				<ul class="Buchheit-footer-link">
					<li *ngFor="let link of footerDataJson.sectionFour.rightSide.rightSideArray;let i=index;"><a
							[href]="link.url">{{link.label}}</a></li>
				</ul>
			</div>
			<div class="col-md-auto pt-3 pt-md-0"
				*ngIf="footerDataJson && footerDataJson.sectionFour && footerDataJson.sectionFour.leftSide">
				{{footerDataJson.sectionFour.leftSide.copyrightText}}
			</div>
		</div>
	</div>
</footer>
