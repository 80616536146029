import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    inputUrl = '';
  parse(url: any): UrlTree {
    this.inputUrl = url;
    const serializer = new DefaultUrlSerializer();
    return serializer.parse(url);
  }

  serialize(tree: UrlTree): any {
    const serializer = new DefaultUrlSerializer();
    return serializer.serialize(tree).replace(/%20/g, '+');
  }
}