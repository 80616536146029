import { Injectable, Inject, PLATFORM_ID, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
  Router
} from '@angular/router';
import { LoginComponent } from '@app/misc/login/login/login.component';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public dialog: MatDialog
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isaccessUrl(state);
  }
  isaccessUrl(state: any): Observable<boolean> {
    const isLoggedIn = localStorage.getItem('isLoggedin');
    if (isLoggedIn) {
      return of(true)
    } else {
      if (state.url.includes('user/yourrebates') || state.url.includes('/giftcard')) {
        if (isPlatformBrowser(this.platformId)) {
          const dialogRef = this.dialog.open(LoginComponent, {
            disableClose: true,
            panelClass: ['pop-up', 'md-pop'],
            data: this.router.url.includes('register') ? true : false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if(state.url.includes('user/yourrebates')){
                this.router.navigate(["/user/yourrebates"])
              } else if(state.url.includes('/giftcard')){
                this.router.navigate(["/giftcards"])
              }
              return of(true);
            } else {
              return of(false);
            }
          });
          this.router.navigate(["/"])
          return of(false);
        }
      } else if (state.url.includes('user') || state.url.includes('checkout')) {
        this.router.navigate(['/']);
        return of(false);
      } else {
        return of(true)
      }
    }
    return of(true);
  }
}
