import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonService } from './common.service';
import { LoginComponent } from '@app/misc/login/login/login.component';
import { isPlatformBrowser } from '@angular/common';
import { GoogleAnalyticsService } from './google-analytics.service';
declare let HawkSearch;

@Injectable({
  providedIn: 'root'
})
export class HawkSearchConfigService {
  hawkSearch: any;
  config: any;
  data = {
    8: {
      price: "8_house_springs_price",
      inventory: "8_house_springs_inventory"
    },
    7: {
      price: "7_jacksonville_price",
      inventory: "7_jacksonville_inventory"
    },
    6: {
      price: "6_herculaneum_price",
      inventory: "6_herculaneum_inventory"
    },
    5: {
      price: "5_greenville_price",
      inventory: "5_greenville_inventory"
    },
    4: {
      price: "4_jackson_price",
      inventory: "4_jackson_inventory"
    },
    3: {
      price: "3_centralia_price",
      inventory: "3_centralia_inventory"
    },
    32: {
      price: "32_north_platte_price",
      inventory: "32_north_platte_inventory"
    },
    31: {
      price: "31_beatrice_price",
      inventory: "31_beatrice_inventory"
    },
    30: {
      price: "30_murray_price",
      inventory: "30_murray_inventory"
    },
    2: {
      price: "2_perryville_price",
      inventory: "2_perryville_inventory"
    },
    29: {
      price: "29_pocahontas_price",
      inventory: "29_pocahontas_inventory"
    },
    27: {
      price: "27_paragould_price",
      inventory: "27_paragould_inventory"
    },
    26: {
      price: "26_jonesboro_price",
      inventory: "26_jonesboro_inventory"
    },
    25: {
      price: "25_columbia_north_price",
      inventory: "25_columbia_north_inventory"
    },
    24: {
      price: "24_columbia_south_price",
      inventory: "24_columbia_south_inventory"
    },
    23: {
      price: "23_fulton_price",
      inventory: "23_fulton_inventory"
    },
    22: {
      price: "22_kirksville_price",
      inventory: "22_kirksville_inventory"
    },
    21: {
      price: "21_blue_springs_price",
      inventory: "21_blue_springs_inventory"
    },
    20: {
      price: "20_jefferson_city_price",
      inventory: "20_jefferson_city_inventory"
    },
    1: {
      price: "1_sparta_price",
      inventory: "1_sparta_inventory"
    }
  };
  constructor(private common: CommonService, private analyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object, private ngZone: NgZone
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // This code will only run on the client side
      this.initializeHawkSearch();
    }
  }
  private initializeHawkSearch(): void {

    this.config = HawkSearch?.config;
    this.hawkSearch = HawkSearch;
  }


  toggleHeart(element: HTMLElement, isSelected: boolean) {
    const notSelected: HTMLElement = element.shadowRoot.querySelector('.not-selected');
    const selected: HTMLElement = element.shadowRoot.querySelector('.selected');
    const addToWishlist: HTMLElement = element.shadowRoot.querySelector('.add-to-wishlist');

    // Set color based on isSelected
    addToWishlist.style.color = isSelected ? '#d1232a' : '';

    // Toggle display based on isSelected
    notSelected.style.display = isSelected ? 'none' : 'inline-block';
    selected.style.display = isSelected ? 'inline-block' : 'none';
  }


  addToCart(data: string, element: HTMLElement) {
    const addBtn: HTMLElement = element.shadowRoot.querySelector('.addBtn');

    const decreaseBtn = element.shadowRoot.querySelector('.decrease-btn');

    const cartControlsHtml = `
      <div class="card-button-group" style="height: 1.75rem !important;">
        <button aria-label="Decrease value" class="decrease-btn" id="decrease-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8" />
            </svg>
          </span>
        </button>
        <div class="mat-form-field">
          <input type="number" min="1" max="100" style="height: 32px !important;" class="mat-input-element" value="1" id="counter-input" aria-invalid="false" aria-required="false" disabled>
        </div>
        <button aria-label="Increase value" class="increase-btn" id="increase-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
            </svg>
          </span>
        </button>
      </div>
    `;

    if (!decreaseBtn && addBtn) {
      addBtn.innerHTML = cartControlsHtml;
      this.attachEventListeners(data, element);
    }
  }

  attachEventListeners(itemId: any, element: any) {
    const decreaseBtn: HTMLElement = element.shadowRoot.querySelector('#decrease-btn');
    const increaseBtn: HTMLElement = element.shadowRoot.querySelector('#increase-btn');
    const counterInput: HTMLInputElement = element.shadowRoot.querySelector('#counter-input');
    // Assuming HawkSearch.searchResponse.results is available globally
    const storeId = localStorage.getItem('storeId');
    const storeKey = this.data[storeId];
    const itemStoreStock = this.returnStockofProduct(itemId, storeKey, counterInput, true);
    const qualifier = JSON.parse(element.data.attributes.qualifier)?.qualifier_id;

    let posidentity = {};
    element.data.attributes.selling_rules?.forEach(item => {
      let parsedElement = JSON.parse(item);
      posidentity[parsedElement.store_id] = parsedElement;
    });
    const maximumPurchaseCount = posidentity[storeId]?.maximumsaleunitcount;


    const handleDecreaseClick = this.debounce(function () {
      const itemStock = self.returnStockofProduct(itemId, storeKey, counterInput, false);
      let bookingData = {};
      let currentValue = parseInt(counterInput.value);
      if (currentValue === 1) {
        const doc = element.data?.attributes;
        const price = doc[storeKey.price]?.[0];
        let item = {
          Item: {
            id: +element.data.id,
            positemqualifier_id: qualifier,
            positemqualifier: qualifier,
            name: doc.name[0],
            brand: doc.brandname?.[0],
          },
          positemqualifier: qualifier,
          quantity: currentValue,
          itemCount: 0,
          positemqualifier_id: qualifier,
          regularPrice: price,
          isRapidUi: true,
          cartBtn: true,
          booking: false,
          category_search: doc.category_search
        };
        let cartData = self.common.cartData?.find(a => a.item_id == (itemId?.item_id || itemId));

        self.common.decreaseCount(item, bookingData, cartData);
        self.analyticsService.removeFromCart(item);
        return;
      } else {
        decreaseBtn.removeAttribute('disabled');
      }
      counterInput.value = (currentValue - 1).toString();
      self.updateButtonStates(element, itemStock);

      let cartData = self.common.cartData.find(a => a.item_id == (itemId?.item_id || itemId));


      const doc = element.data?.attributes;
      const price = doc[storeKey.price]?.[0];
      const item = {
        Item: {
          id: +element.data.id,
          positemqualifier_id: qualifier,
          positemqualifier: qualifier,
          name: doc.name[0],
          brand: doc.brandname?.[0],
        },
        positemqualifier: qualifier,
        quantity: currentValue,
        itemCount: currentValue,
        positemqualifier_id: qualifier,
        regularPrice: price,
        isRapidUi: true,
        booking: false,
        cartBtn: true,
        category_search: doc.category_search
      };

      // Call decreaseCount function
      self.common.decreaseCount(item, bookingData, cartData);
    }, 500);

    decreaseBtn?.addEventListener('click', handleDecreaseClick);

    // Your existing function wrapped in the throttling function
    const self = this;
    const handleClick = this.debounce(function () {
      const itemStock = self.returnStockofProduct(itemId, storeKey, counterInput, false);
      let currentValue = parseInt(counterInput.value, 10);

      // Increase currentValue only if maximumPurchaseCount is set
      let updatedValue;
      if (maximumPurchaseCount) {
        updatedValue = currentValue + 1;
      }

      // Determine the maximum allowable count based on the conditions
      const maxCount = maximumPurchaseCount > 0
        ? Math.min(itemStoreStock, maximumPurchaseCount)
        : itemStoreStock;

      if ((updatedValue || currentValue) <= maxCount) {
        // If currentValue matches the maximum allowable count
        if ((updatedValue || currentValue) === maxCount) {
          counterInput.value = updatedValue.toString();
          increaseBtn?.setAttribute('disabled', 'disabled');
        } else if (currentValue === +itemStock) {
          increaseBtn.setAttribute('disabled', 'disabled');
        } else {
          counterInput.value = (currentValue + 1).toString();
          increaseBtn.removeAttribute('disabled');
        }

        self.updateButtonStates(element, maxCount);

        let cartData = false;
        if (self.common.cartData.length) {
          self.common.cartData.forEach((a) => {
            if (a.item_id === (itemId?.item_id || itemId)) cartData = a;
          });
        }

        const doc = element.data?.attributes;
        const price = doc[storeKey.price]?.[0];
        const item = {
          Item: {
            id: +element.data.id,
            positemqualifier_id: qualifier,
            positemqualifier: qualifier,
            name: doc.name[0],
            brand: doc.brandname?.[0],
          },
          positemqualifier: qualifier,
          quantity: currentValue,
          itemCount: currentValue,
          positemqualifier_id: qualifier,
          regularPrice: price,
          isRapidUi: true,
          booking: false,
          cartBtn: true,
          category_search: doc.category_search
        };

        // Call increaseCount function
        self.common.increaseCount(item, {}, cartData);
      }
    }, 500);

    increaseBtn?.addEventListener('click', handleClick);

    // Update button states initially
    const maxCount = maximumPurchaseCount > 0
      ? Math.min(itemStoreStock, maximumPurchaseCount)
      : itemStoreStock;
    this.updateButtonStates(element, maxCount);
  }


  debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function (...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }


  returnStockofProduct(item: any, storeKey: any, counterInput: any, patch: boolean): number {
    let stock = 0;
    HawkSearch?.searchResponse.results.find((x) => {
      if ((+x.id === +(item?.item_id || item))) {
        stock = +item?.rest_stock || +x.attributes[storeKey.inventory][0];
        patch && (counterInput.value = item?.quantity || item?.item_qty || '1');
      }
    });
    return stock;
  }

  updateButtonStates(element: HTMLElement, stock: number) {
    const decreaseBtn: HTMLElement = element.shadowRoot.querySelector('#decrease-btn');
    const increaseBtn: HTMLElement = element.shadowRoot.querySelector('#increase-btn');
    const counterInput: HTMLInputElement = element.shadowRoot.querySelector('#counter-input');

    let currentValue = parseInt(counterInput?.value);

    if (currentValue >= stock) {
      increaseBtn?.classList.add('active');
      increaseBtn?.setAttribute('disabled', 'disabled');
    } else {
      increaseBtn?.classList.remove('active');
      increaseBtn?.removeAttribute('disabled');
    }

    if (currentValue <= 1) {
      decreaseBtn?.classList.remove('active');
    } else {
      decreaseBtn?.classList.remove('active');
      decreaseBtn?.removeAttribute('disabled');
    }
  }


  doFavorite(item: any, element: HTMLElement, isSelected: boolean) {
    if (!item) return;

    this.common.checkLogInState();

    const updateWishlist = (wishlistStatus: boolean, successMessage: string) => {
      item.wishlist = wishlistStatus;
      const searchResult = this.hawkSearch.searchResponse.results.find((ele: any) => ele.id == item.Item.id);
      if (searchResult) {
        searchResult.wishlist = wishlistStatus;
      }
      this.common.getWishList();
      this.ngZone.run(() => {
        this.common.openSnackbar(successMessage);
      })
      const priceTag = element?.shadowRoot.querySelector('.search-results-list__item__price');
      const currentPriceElement = priceTag.querySelector('.search-results-list__item__price__current');

      // Check if the element exists and get its text content
      let currentPrice;
      if (currentPriceElement) {
        currentPrice = currentPriceElement.textContent.trim();
        currentPrice = currentPrice.replace('$', '');
        item.offer_price = +currentPrice;
      }
      isSelected && this.analyticsService.addToWishlist(item);
      this.toggleHeart(element, isSelected);
    };

    const handleWishlistResponse = (wishlistStatus: boolean, successMessage: string) => {
      return (value: any) => {
        if (value) {
          updateWishlist(wishlistStatus, successMessage);
        }
      };
    };

    const handleWishlistError = (error: any) => {
      this.common.openSnackbar(error.message);
    };

    const addToWishlist = () => {
      const arr = [`${item.Item.id},${item.positemqualifier}`];
      const obj = { item_ids: arr };
      this.common.wishListData(obj, item.wishlist).subscribe(
        handleWishlistResponse(true, "Product has been added to your wishlist"),
        handleWishlistError
      );
    };

    if (item.wishlist) {
      this.common.wishListData(item.Item.id, item.wishlist).subscribe(
        handleWishlistResponse(false, "Product has been removed from your wishlist"),
        handleWishlistError
      );
    } else
      if (!this.common.isLoggedin) {
        const dialogRef = this.common.dialog.open(LoginComponent, {
          panelClass: ['pop-up', 'md-pop', 'login-pop']
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            addToWishlist();
          }
        });
      } else {
        addToWishlist();
      }
  }


}
