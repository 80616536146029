import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { DataApiService } from './http/data-api.service';
import { AuthService } from './auth.service';
import { CartService } from '@app/shared/service/cart.service';
import { CommonService } from './common.service';
import { LoginComponent } from '@app/misc/login/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  constructor(public commonService: CommonService, public cartService: CartService, private auth: AuthService, @Inject(PLATFORM_ID) private platformId: Object, private dataApiService: DataApiService, public dialog: MatDialog, private ngZone: NgZone) {    
    // this.callInitialCheck()/
  }
  callInitialCheck(){
    /**when user opens the browser and if the user is logged in the it will check the last activity time*/
    if(isPlatformBrowser(this.platformId) && localStorage.getItem('isLoggedin')){
      const now = Date.now();
      const lastActive = parseInt(this.getLastAction())
      if (!isNaN(lastActive)) {
        const diff = now - lastActive;
        const isTimeout = diff > (60 * 60 * 1000);
        // const isTimeout = diff > (10000);
        if (isTimeout && localStorage.getItem('isLoggedin')) {
          localStorage.removeItem('lastAction');
          this.logout();
          this.cartService.setIsCartSet(true);
          this.commonService.openSnackbar('You’ve been logged out due to inactivity. Please log in again.');
          this.openLoginPopup();
        }
      }

      this.setLastAction(Date.now());
      this.check();
      this.initListener();
      this.initInterval();
    }
  }

  /**
   * last action
   */
  getLastAction() {
    return localStorage.getItem('lastAction');
  }

  /**
   * set last action
   * @param value
   */
  setLastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value))
  }

  /**
   * start event listener
   */
  initListener() {
    this.ngZone.runOutsideAngular(() => {
      /*
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
        } else {
          // audio.play();
          console.log('lodi');          
        }
      });
      */
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
    });
  }

  /**
   * time interval
   */
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    })
  }

  /**
   * reset timer
   */
  reset() {
    if (localStorage.getItem('isLoggedin')) {
      this.setLastAction(Date.now());
    } else {
      localStorage.removeItem('lastAction')
    }
  }

  /**
   * check timer
   */
  check() {
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + (60 * 60 * 1000); /**set time for 1hr */
    // const timeLeft = parseInt(this.getLastAction()) + 10000;
    if (!isNaN(timeLeft)) {
      const diff = timeLeft - now;
      const isTimeout = diff < 0;
      this.ngZone.run(() => {
        if (isTimeout && localStorage.getItem('isLoggedin')) {
          localStorage.removeItem('lastAction');
          this.logout();
          this.commonService.openSnackbar('You’ve been logged out due to inactivity. Please log in again.');
          this.openLoginPopup();
        }
      });
    }
  }
  logout() {
    this.commonService.cartDetails = [];
    this.auth.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: '', auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', buchheit_token: '' }));
    this.auth.auth = '';
    this.auth.removeUserCredential();
    this.commonService.setCartItems({ 'cartCount': 0, 'cartDetails': [] });
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('cartDetailsStorage');
    localStorage.removeItem('is_stock');
    this.cartService.setIsInStock(null);
  }

  openLoginPopup() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['pop-up', 'md-pop', 'login-pop'],
      data: false
    });
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 60000)
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataApiService.reloadCurrentRoute();
      }
    });
  }
}