import { AfterViewInit, Component, DoCheck, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '@app/core/services/utility.service';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UploaderService } from '@app/core/services/uploader.service';
import { CartService } from '@app/shared/service/cart.service';
import { LoginComponent } from '@app/misc/login/login/login.component';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { HawkSearchConfigService } from '@app/core/services/hawk-search-config-service.service';

@Component({
  selector: 'app-cart-add',
  templateUrl: './cart-add.component.html',
  styleUrls: ['./cart-add.component.scss']
})
export class CartAddComponent implements OnInit, DoCheck {

  FavoriteBox = false;
  CartBtn = false;
  cartDataInfo: any;

  userid: string;
  customerOrderId: string;
  customerId: string;
  storeId = localStorage.getItem('storeId');
  businessUnitId = localStorage.getItem('businessUnitId');
  public itemIdArr: any = [];
  isLoggedin: boolean = false;

  defaultImage: string = '';
  cartCount: number = 0;
  isPlaceOrderDisabled: boolean = true;
  restStockArr: any = [];
  qualifierId: number;
  isBookingCart: boolean = localStorage.getItem('booking_flag') === 'true';
  bookingData: any;
  bookingPayload: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public commonService: CommonService, private dataApiService: DataApiService, public utilityService: UtilityService, public router: Router, private auth: AuthService, public dialog: MatDialog, private upload: UploaderService, private cartService: CartService, private elementRef: ElementRef, private hawkSearchService: HawkSearchConfigService) { }
  ngDoCheck(): void {
    this.bookingPayload = {};
    const bookingFlag = localStorage.getItem('booking_flag') === 'true';
    const bookingData = this.cartService.getBookingData();

    if (bookingFlag && Object.keys(bookingData || {}).length) {
      const bookingStore = JSON.parse(localStorage.getItem('booking_store') || 'null');

      if (bookingStore) {
        this.bookingPayload = {
          ordertype: 'booking',
          booking_id: +bookingData.booking_id,
          event_id: +bookingData.event_id,
          storeid: +bookingStore
        };
      }
    }

    // Update properties after all operations
    this.isBookingCart = bookingFlag;
    this.bookingData = bookingData;
  }


  ngOnInit(): void {


    this.auth.authToken.subscribe((value: any) => {
      this.isLoggedin = value.isLoggedin === 'true' ? true : false;
    });

    this.defaultImage = this.upload.bucketUrl + 'items//fit-in/300x300/' + 'default-item-image.png';
    let element = this.commonService.cartItems;

    this.cartDataInfo = element.cartDetails;
    this.cartCount = element.cartCount;
  }


  getImageUrl(imageName: string, status: boolean, width?: number, height?: number, image_timestamp?: string): string {
    return this.utilityService.getLazyImage(imageName, 'items', status, `${width}x${height}`, image_timestamp);
  }

  ProductDetailsPageUrl(item: any): string {
    if (!item) {
      return '';
    }

    let positemqulifier_id = item?.positemqulifier_id || '';
    let url = '';

    if (item && (item.hasOwnProperty('item_slug') || item.hasOwnProperty('id'))) {
      const itemId = item.item_slug || item.id;
      url = !this.isBookingCart ? `/p/${itemId}` : `/b/${itemId}/${+this.bookingData?.booking_id}/${+this.bookingData?.event_id}/${+this.bookingPayload?.storeid}`;
    }

    if (positemqulifier_id) {
      url = `${url}/${positemqulifier_id}`;
    }

    return url;
  }


  get getCartItemArray() {
    this.isPlaceOrderDisabled = true;
    let cartItemArray: any = this.cartService.getCartItemArray();

    cartItemArray.forEach((element: any, index: any) => {

      if (element.hasOwnProperty('itemdata')) {
        const itemdata = element.itemdata;
        element.item_id = itemdata.Item.id;
        let positemqualifier: any = itemdata.positemqualifier || element.positemqualifier;
        itemdata.Item.positemqulifier_id = positemqualifier;
        let selectedQualifier: any = itemdata.positemqualifiers.filter((item: any) => item.positemqulifier === positemqualifier);
        itemdata.positemqualifierName = selectedQualifier.length > 0 ? selectedQualifier[0].positemqulifiername : '';
        element.offer_price = ('offer_price' in element) ? element.offer_price : {};
        element.positemqualifier = positemqualifier;
        element = { ...element, ...itemdata }
        cartItemArray[index] = this.commonService.getProductDetails(element);
        delete element.itemdata;
      }
    });

    // let notAllowItemArray: any = cartItemArray.filter((item: any, index: number) => item.quantity > (stock ?? item.actual_stock) || (item.maximumsaleunitcount > 0 && item.quantity > item.maximumsaleunitcount));
    let notAllowItemArray: any = cartItemArray.filter((item: any, index: number) => item.quantity > (item?.rest_stock) || (item.maximumsaleunitcount > 0 && item.quantity > item.maximumsaleunitcount));
    if (notAllowItemArray.length == 0) {
      this.isPlaceOrderDisabled = false;
    }
    return cartItemArray;
  }
  placeOrder() {

    /*if(this.isPlaceOrderDisabled){
      this.commonService.openSnackbar("Please remove 'Out of stock' or 'Maximum buying limit exceeded' product(s) from your cart then you can process to next step.");
    }
    else{*/
    if (this.getCartItemArray.length > 0) {
      if (this.isLoggedin) {
        this.handleLoggedInUser();
      } else {
        this.handleAnonymousUser();
      }

      this.removeActiveClassFromElements("Location-Box", "Store-Box", "Cart-Box");
    }
  }
  handleLoggedInUser(): void {
    const customerOrderId = this.getCartItemArray[0]?.customerorder;

    if (customerOrderId) {
      localStorage.setItem('customerorder_id', customerOrderId);
    }

    const destinationRoute = this.isBookingCart ? '/cart' : '/checkout/order-review';
    setTimeout(() => {
      this.router.navigate([destinationRoute]);
    }, 300);
  }

  handleAnonymousUser() {
    if(this.isBookingCart){
      this.router.navigate(['/cart']);
      return;
    }
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['pop-up', 'md-pop', 'login-pop']
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handleLoggedInUser();
        setTimeout(() => {
          this.dataApiService.reloadCurrentRoute();
        }, 200);

      }
    });
  }

  removeActiveClassFromElements(...elementIds: string[]): void {
    elementIds.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.remove("active");
      }
    });
  }

  cartBoxClose() {
    let element = document.getElementById("Cart-Box");
    element.classList.remove("active");
  }

  manageRapidUiQty() {
    if(this.router.url.includes('listing')) {
      return;
    }
    const nodeList: any = this.getSearchResultItems();
    const cartData = this.cartService.getCartItemArray();
    if (cartData?.length && nodeList?.length) {
      for (let i = 0; i < nodeList.length; i++) {
        const matchedItem = cartData.find((x: any) => nodeList[i].data.id == x.item_id);
        if (matchedItem?.item_id) {
          const counterInput: HTMLInputElement = nodeList[i].shadowRoot.querySelector('#counter-input');
          const increaseBtn: HTMLElement = nodeList[i].shadowRoot.querySelector('#increase-btn');
          if (matchedItem?.itemCount > 0 && counterInput) {
            counterInput.value = matchedItem?.itemCount;
            const maxCount = matchedItem?.maximumsaleunitcount > 0
              ? Math.min(matchedItem?.rest_stock, matchedItem?.maximumsaleunitcount)
              : matchedItem?.rest_stock;

            if (matchedItem?.itemCount >= maxCount) {
              increaseBtn?.classList.add('active');
              increaseBtn?.setAttribute('disabled', 'disabled');
            } else {
              increaseBtn?.classList.remove('active');
              increaseBtn?.removeAttribute('disabled');
            }
          }
        }
      }
    }
  }

  getSearchResultItems(): NodeListOf<any> {
    return document.querySelector('hawksearch-search-results')?.shadowRoot.querySelector('hawksearch-search-results-list')?.shadowRoot.querySelectorAll('hawksearch-search-results-item');
  }


}
