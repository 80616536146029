import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { MobNavComponent } from '../mob-nav/mob-nav.component';
import { NavComponent } from '../nav/nav.component';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  isEditor: boolean;
  showLoader: boolean;
  loaderSubscription: any;
  MobileNav: boolean;
  DesktopNav: boolean;
  @ViewChild(NavComponent) navComponent: NavComponent;
  @ViewChild(MobNavComponent) mobileNavComponent: MobNavComponent;
  constructor(public commonService: CommonService, private cdRef: ChangeDetectorRef, private router: Router, private authService: AuthService) {
    this.isEditor = false;
    this.showLoader = false;
    this.MobileNav = false;
    this.DesktopNav = true;
  }

  ngOnInit(): void {
    this.resize();
    this.loaderSubscription = this.authService.globalRouterLoaderShow.subscribe(
      res => {
        this.showLoader = res;
      }
    )
  }
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  getNavigation() {
    if (!this.isCheckoutPage()) {
      if (this.DesktopNav) {
        setTimeout(() => {
          this.navComponent.ngOnInit();

        }, 1);
      }
      if (this.MobileNav)
        this.mobileNavComponent.ngOnInit();
    }
  }

  changeMegaMenu(){
    if(this.DesktopNav){;
      if(typeof this.navComponent !== 'undefined'){
        this.navComponent.isFirstTimeMegaMenuClicked = true;
        this.navComponent.navMenuList = [];
        this.navComponent.hoverMenu = [];
        this.navComponent.MegaMenuBox = false;
      }
    } else {
      if(typeof this.mobileNavComponent !== 'undefined'){
        this.mobileNavComponent.navMenuList = [];
        this.mobileNavComponent.getParentTaxonomyNavigation();
      }
    }
  }


  isYourAccountPage() {
    return this.router.url.includes('/user/youraccount');
  }


  isCheckoutPage() {
    return this.router.url.includes('/checkout');
  }
  isGuestCheckoutPage() {
    const url = this.router.url;
    return url.includes('/guest-user/checkout-billing') || url.includes('/guest-user/order-complete') || url.includes('/guest-user/order-overview');
  }
  isGiftcardCheckoutPage() {
    return this.router.url.includes('/giftcards/giftcard-shipping') || this.router.url.includes('/giftcards/giftcard-billing');
  }

  resize(): void {
    if (window.innerWidth < 1100) {
      this.MobileNav = true;
      this.DesktopNav = false;
    }
    else {
      this.MobileNav = false;
      this.DesktopNav = true;
    }
  }

  get canShowFooter(){
    return (this.router.url === '/' || this.router.url === '/home' ||  (!this.router.url.includes('/', 1)&&this.router.url!=='/cart') && this.MobileNav && !this.router.url.startsWith('/listing?search=')) || !this.MobileNav;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resize();
  }
}
