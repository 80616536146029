import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  navMenu:any[] = [];

  constructor() { }

  getItemGroupLevelById(id: any) {
    const data:any = this.navMenu.find(menu=>menu.navigationitem_value==id);
    return data?.navigationitem_label;
  }
}
