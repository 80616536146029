import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }

  environments: any = environment;

  /**
   * Method to validate image files.
   * @returns boolean of true if image file is valid else false.
   */
  validateImageOrVideoFile(file: any, fileSize: number, sizeString: string, isImageFile: boolean): { message: string, isValid: boolean } {
    if (isImageFile) {
      if ((file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' || file.type === 'image/webp') && file.size <= fileSize) {
        return { message: '', isValid: true };
      }
      else if ((file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' || file.type === 'image/webp') && file.size > fileSize) {
        return { message: `Uploaded file is greater than ${sizeString}.`, isValid: false };
      }
      else if (file.type !== 'image/jpeg' ||
        file.type !== 'image/png' ||
        file.type !== 'image/jpg' ||
        file.type !== 'image/gif' || file.type !== 'image/webp' || file.type === 'image/gif') {
        return { message: `Unsupported image format.`, isValid: false };
      }
    }
    else {
      if (file.type.indexOf('video') > -1 && file.size <= fileSize) {
        return { message: '', isValid: true };
      }
      else if (file.type.indexOf('video') > -1 && file.size > fileSize) {
        return { message: `Uploaded file is greater than ${sizeString}.`, isValid: false };
      }
      else if (file.type.indexOf('video') === -1) {
        return { message: `Unsupported video format.`, isValid: false };
      }
    }
  }

  validatFileSize(file: any, fileSize: number, sizeString: string) {
    if (file.size <= fileSize) {
      return { message: '', isValid: true };
    } else {
      return { message: `Uploaded file is greater than ${sizeString}.`, isValid: false };
    }
  }

  /**
   * Method to return image based on image name and module name.
   * @param imageName Name of the image.
   * @param moduleName Name of the module.
   * @returns Image URL.
   */
  getImage(imageName: string, moduleName: string, width?: number, height?: number, cover?: boolean, calledFrom?: string): string {
    // console.log(calledFrom);
    

    let data: any = calledFrom == 'dataTable' ? {
      "bucket": environment.imageBucket,
      "key": `${moduleName}/${imageName}`
    } : {
      "bucket": environment.imageBucket,
      "key": `${moduleName}/${imageName}`,
      "edits": {
        "resize": {
          "width": width,
          "height": height,
          "fit": "inside"
        }
      }
    };

    let dimension = '600x600';
    if (width && height) {
      dimension = `${width}x${height}`;
    }
    let fitIn = 'fit-in/';
    if (cover === true) {
      fitIn = '';
    }
    if (imageName && imageName !== '' && moduleName && moduleName !== ''
      && imageName !== `../../../../../../assets/images/blank.png` && !imageName.includes('base64')) {
      //return `${environment.docUrl}${btoa(JSON.stringify(data))}`;
      // return `${environment.docUrl}${moduleName}//${fitIn}${dimension}/${imageName}`;
      return this.environments.isDevServer ? `${environment.docUrl}${this.encodingData(data)}` : `${environment.docUrl}${moduleName}//${fitIn}${dimension}/${imageName}`;
    }
    else if (imageName && imageName !== '' && moduleName && moduleName !== ''
      && imageName !== `../../../../../../assets/images/blank.png` && imageName.includes('base64')) {
      return imageName;
    }
    return '';
  }

  getLazyImage(imageName: string, moduleName: string, isDefault: boolean, size: string,timestamp?:any, pattern?: string, isBlur?: boolean): string {
    let newName: string = '';
    let extension: string = '';
    if (timestamp) {
      const parts = imageName.split('.');
      newName = parts.slice(0, -1).join('.');
      extension = parts[parts.length - 1];
     imageName = `${newName}_${timestamp}.${extension}`;
    }


    
    let data: any = {
      "bucket": environment.imageBucket,
      "key": `${moduleName}/${imageName}`,
      "edits": {
        "resize": {
          "width": size.includes('x') ? size.split('x')[0] : 100,
          "height": size.includes('x') ? size.split('x')[1] : 100,
          "fit": "inside"

        }
      }
    };
    if (imageName && imageName !== '' && moduleName && moduleName !== '' && typeof imageName === 'string'
      && imageName !== `../../../../../../assets/images/blank.png`
      && imageName !== `../../../../../assets/images/image.svg`
      && !imageName.includes('base64')) {
      if (isDefault) {
        return this.environments.isDevServer ? `${environment.docUrl}${this.encodingData(data)}` : `${environment.docUrl}${moduleName}/fit-in/${size}/filters:blur(${isBlur ? 1 : 50})/${imageName}`;
      }
      return this.environments.isDevServer ? `${environment.docUrl}${this.encodingData(data)}` : `${environment.docUrl}${moduleName}//fit-in/${size}/${imageName}`;
    }
    else if (imageName && imageName !== '' && moduleName && moduleName !== '' && typeof imageName === 'string'
      && imageName !== `../../../../../../assets/images/blank.png`
      && imageName !== `../../../../../assets/images/image.svg`
      && imageName.includes('base64')) {
      return imageName;
    }
    else if (imageName === `../../../../../assets/images/image.svg`) {
      return imageName;
    }
    if (imageName === '') {
      data.key = `assets/images/default-item-image.png`;
      if (isDefault) {
        return this.environments.isDevServer ? `${environment.docUrl}${this.encodingData(data)}` : `${environment.docUrl}assets/images/fit-in/${size}/filters:blur(50)/default-item-image.png`;
      }
      return this.environments.isDevServer ? `${environment.docUrl}${this.encodingData(data)}` : `${environment.docUrl}assets/images//fit-in/${size}/default-item-image.png`;
    }
    return '';
  }
  encodingData(data: any) {
    const text = JSON.stringify(data);
    const encoder = new TextEncoder();
    const dataUtf8 = encoder.encode(text);
    const encodedData = btoa(String.fromCharCode.apply(null, dataUtf8));
    return encodedData;
  }
}
