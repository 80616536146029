<div mat-dialog-title>
  What email would you like to use for your Ebates?
</div>
<div mat-dialog-content style="overflow: inherit;">
  <mat-form-field appearance="outline" color="primary">
    <mat-label>Email</mat-label>
    <input matInput [formControl]="ebateEmail" required="" />
    <mat-icon aria-label="icon" class="material-icons-outlined" matSuffix>email</mat-icon>
    <mat-error *ngIf="ebateEmail.hasError('required')">
      Email is required.
    </mat-error>
    <mat-error *ngIf="ebateEmail.hasError('email')">
      Please enter a valid email.
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button aria-label="button" mat-flat-button color="accent" cdkFocusInitial class="sm-btn" (click)="confirm()">Continue</button>
  <button aria-label="button" mat-stroked-button color="accent" class="sm-btn" mat-dialog-close>Cancel</button>
</div>
