import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SearchModule } from "../../search/search.module";
import { MiscMaterialModule } from '../misc-material/misc-material.module';
import { LoginModule } from '../login/login.module';
// import {BodyLoaderModule} from '../../body-loader/body-loader.module'
// import { BodyLoaderComponent } from './body-loader/body-loader.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { MobNavComponent } from './mob-nav/mob-nav.component';
import { LoginComponent } from '../login/login/login.component';
import { CartAddComponent } from '../../cart-add/cart-add.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonService } from '@app/core/services/common.service';
import {AlertModule} from '@app/alert/alert.module';
import { BodyLoaderModule } from '@app/body-loader/body-loader.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
    declarations: [
        LayoutComponent,
        HeaderComponent,
        NavComponent,
        FooterComponent,
        MobNavComponent,
        // BodyLoaderComponent,
        CartAddComponent,
        PageNotFoundComponent,
    ],
    imports: [
        CommonModule,
        SearchModule,
        MiscMaterialModule,
        BodyLoaderModule,
        LazyLoadImageModule,
        RouterModule,
        LoginModule,
        AlertModule
    ],
    exports: [LayoutComponent, HeaderComponent],
    providers: [CommonService]
})
export class LayoutModule { }
