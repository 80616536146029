import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { environment } from "../environments/environment";
import { MetaService2 } from './core/services/meta.service';
import { AutoLogoutService } from './core/services/auto-logout.service';
declare global {
  interface Window {
    stipeKey: string;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'buchheit-frontend';
  showLoader: boolean;
  loaderSubscription: any;
  isLoggedIn = JSON.parse(localStorage.getItem('isLoggedin'));

  constructor(public commonService: CommonService, private auth: AuthService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2, private metaService: MetaService2, public autoLogout: AutoLogoutService) {
    window.stipeKey = environment.stipePublicKey;
    this.showLoader = false;
    this.initializeduserinformation();
    //this.auth.ondefaultsetSessionstorage();
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEndEvent(this.router, this.platformId);
        this.autoLogout.callInitialCheck();
        this.handleCheckoutEvent(event, this.router);
        this.toggleSearchBar();
        this.handleJsonLdPusher();
      }
      this.handleRedirectedUrl(event, this.renderer);
    });

  }
  handleNavigationEndEvent(router: Router, platformId: Object): void {
    if (router.url === '/about-us/welcome-to-buchheit') {
      router.navigateByUrl('about-us');
    }

    if (isPlatformBrowser(platformId) && !router.url.startsWith(window.location.pathname)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  handleCheckoutEvent(event: any, router: Router): void {
    if (event.id === 1 && event.url === '/checkout/checkout-billing') {
      router.navigate(['/checkout/order-review']);
    }else if(event.id === 1 && event.url === '/guest-user/checkout-billing'){
      router.navigate(['/guest-user/order-overview']);
    }
  }

  toggleSearchBar(): void {
    const searchBarContainer = document.getElementsByClassName('searchBar') as HTMLCollectionOf<HTMLElement>;
    if (searchBarContainer.length > 1 && searchBarContainer[1].classList.contains('active')) {
      searchBarContainer[1].classList.toggle('active');
    } else if (searchBarContainer.length > 1 && searchBarContainer[0].classList.contains('active')) {
      searchBarContainer[0].classList.toggle('active');
    }
  }

  handleJsonLdPusher(): void {
    if (!environment.isDevServer) {
      this.jsonLdPusher();
    }
  }

  handleRedirectedUrl(event: any, renderer: Renderer2): void {
    if (event.hasOwnProperty('urlAfterRedirects')) {
      let loader = renderer.selectRootElement('#loading');
      renderer.setStyle(loader, 'display', 'none');
    }
  }
  initializeduserinformation() {
    this.auth.getUserCredentials();
  }

  ngAfterViewInit(): void {
    this.loaderSubscription = this.commonService.globalRouterLoaderShow.subscribe(
      res => {
        this.showLoader = res;
      }
    )
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }
  jsonLdPusher() {
    const jsonLdData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Buchheits",
      "url": "https://www.buchheits.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.buchheits.com/listing?search={search_term_string}",
        "query-input": "required name=search_term_string"
      },
      "description": "Welcome to Buchheits where we help you Explore the Possibilities of a Simpler Life.",
      "image": {
        "@type": "ImageObject",
        "url": "https://images.buchheits.com/assets/images/logo.png",
        "width": 290,
        "height": 60
      },
      "sameAs": [
        "https://www.facebook.com/buchheitstores/",
        "https://twitter.com/buchheitstores?lang=en"
      ]
    };
    // Find the existing JSON-LD script element
    // const existingJsonLd = document.querySelector('script[type="application/ld+json"]');
    const existingJsonLd = document.querySelector('#home');
    // If an existing JSON-LD script element was found, remove it from the DOM
    if (!existingJsonLd) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'home';
      script.text = JSON.stringify(jsonLdData);
      this.renderer.appendChild(document.head, script);
    }


  }
}
