<nav class="Buchheit-nav" [ngClass]="{'sticky' : isSticky}">
	<div class="Buchheit-megamenu-wrapper">
		<div class="Buchheit-megamenu " [ngClass]="MegaMenuBox ? 'active' : ''">
			<div class="Buchheit-megamenu-list-box">
				<div class="Buchheit-megamenu-list-heading d-none d-lg-flex" (click)="MegaMenuBox = !MegaMenuBox;">
					<mat-icon aria-label="icon">menu</mat-icon> {{navMenuName}}
				</div>
				<div class="Buchheit-megamenu-list-heading d-lg-none">
					<button aria-label="button" mat-icon-button color="white" (click)="MegaMenuBox = !MegaMenuBox;"
						class="sm-btn">
						<mat-icon aria-label="icon" class="m-0">arrow_back_ios_new</mat-icon>
					</button>
					{{navMenuName}}
				</div>
				<ul class="Buchheit-megamenu-list">
					<li *ngFor="let item of navMenuList;let i=index" matRipple [ngClass]="{'active': menu==i }"
						(mouseover)="mouseover(item,i)" (mouseleave)="mouseleave()">
						<mat-icon aria-label="icon">{{item.icon || 'category'}}</mat-icon>
						<a href="/shop/{{item.merchandisehierarchygroupslug}}" (click)="goCategoryPage($event,item)">
							{{item.merchandisehierarchygroupname}}
						</a>
						<mat-icon aria-label="icon" class="ml-auto mr-0 text-accent d-lg-none">chevron_right</mat-icon>
					</li>
				</ul>
			</div>
			<div class="Buchheit-megamenu-sub active">
				<div class="Buchheit-megamenu-sub-heading">
					{{hoverMenu.merchandisehierarchygroupname}}
				</div>
				<div class="Buchheit-megamenu-sub-box">
					<ng-container *ngIf="isBrowser">
						<ngx-masonry *ngIf="!commonService.isUserAgentForSpeedTest()" horizontalOrder="true"
							class="Buchheit-megamenu-sub-list">
							<ul class="Buchheit-megamenu-sub-masonry">
								<li ngxMasonryItem class="sub-list-item"
									*ngFor="let subItem of hoverMenu.children;let j=index">
									<a href="/search?category={{subItem.merchandisehierarchygroup_id}}"
										(click)="goListingPage($event,subItem)">
										<div class="Buchheit-megamenu-sub-name">
											{{subItem.merchandisehierarchygroupname}}

										</div>
									</a>
									<ng-container *ngFor="let thirdItem of subItem.children; let k=index">
										<div class="Buchheit-megamenu-third" [ngClass]="MegaMenuThird ? 'active' : ''">
											<a href="/search?category={{thirdItem.merchandisehierarchygroup_id}}"
												(click)="goListingPage($event,thirdItem)">{{thirdItem.merchandisehierarchygroupname}}</a>
										</div>
									</ng-container>
								</li>
								<!-- <li ngxMasonryItem class="sub-list-item"
									*ngIf="hoverMenu && hoverMenu.children && hoverMenu.children.length == 0">
									<div>No Item Availabel!</div>
								</li> -->
							</ul>
						</ngx-masonry>
					</ng-container>


					<div class="Buchheit-megamenu-sub-image" *ngIf="hoverMenu.nav_image">
						<img [src]="getImageUrl(hoverMenu.nav_image)" alt="capture" width="100" height="100">
					</div>
				</div>
			</div>
		</div>
		<div class="Buchheit-megamenu-bg" (click)="MegaMenuBox = !MegaMenuBox"></div>
	</div>
	<ul class="Buchheit-nav-menu">
		<li matRipple [matRippleColor]="white" class="Buchheit-nav-catagory">
			<a (click)="menuShowList(true)">
				<mat-icon aria-label="icon" class="d-none d-lg-block">menu</mat-icon> {{navMenuName}}
				<mat-icon aria-label="icon" color="accent" class="d-lg-none ml-auto mr-0">chevron_right</mat-icon>
			</a>
		</li>
		<li matRipple *ngFor="let menu of navHeaderMenu;let i=index" (click)="menuClick(menu)"
			[ngClass]="{'active': isSelectedHeader(menu)}">
			<a href="/" (click)="$event.preventDefault()">{{menu.navigationitem_label}}</a>
		</li>
	</ul>
</nav>