import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListingServiceService {

  globalFilterShow: BehaviorSubject<any>;
  globalFilterShow_: Observable<any>;

  sortByFilterFired: BehaviorSubject<any>;
  sortByFilterFired_: Observable<any>;


  constructor() { 

    this.globalFilterShow = new BehaviorSubject(false);
    this.globalFilterShow_ = this.globalFilterShow.asObservable();

    this.sortByFilterFired = new BehaviorSubject(false);
    this.sortByFilterFired_ = this.sortByFilterFired.asObservable();

  }

  setGlobalFilter(payload: any) {
    this.globalFilterShow.next(payload);
  }

  sortByFilterValue(payload: any){
    this.sortByFilterFired.next(payload);
  }

 

  // filterRemove(filter:any):Observable{
  //   console.log(filter);
  //   return
  // }
}
