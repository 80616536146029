import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataApiService } from './http/data-api.service';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
// import { CommonService } from '@app/core/services/common.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  globalRouterLoaderShow: BehaviorSubject<boolean>;
  globalRouterLoaderShow_: Observable<any>;

  user: string = '';
  userfirstname: string = '';
  userlastname: string = '';
  userId: string = '';
  isLoggedin: string = '';
  auth: string = '';
  username: string = '';
  customer_id: string = '';
  customerorder_id = '';
  phone: string = ''
  rememberAuth: boolean = false;
  buchheitToken: string;

  authToken: BehaviorSubject<any> = new BehaviorSubject<any>({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: "", auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', buchheit_token: '' });

  wishlistData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  storeWiseProductStock: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  reviewAfterOrder: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  cartOrderId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  couponVerify: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(readonly dataApiService: DataApiService, public afAuth: AngularFireAuth, public router: Router
    // public commonService: CommonService
    ) {
    this.globalRouterLoaderShow = new BehaviorSubject(false);
    this.globalRouterLoaderShow_ = this.globalRouterLoaderShow.asObservable();
  }


  myOrderReview(payload: any) {
    // console.log(payload)
    this.reviewAfterOrder.next(payload);
  }
  getCartOrderId(payload: string) {
    this.cartOrderId.next(payload);
  }

  getCouponVerify(payload:string){
    this.couponVerify.next(payload);
    // this.couponVerify.complete();
  }
  /**
   * Method to post login Based on username and password.
   * @param bodyobject object of key value;
   * @returns Observable of Category page.
   */
  postLogin(bodyobject: any): any {
    return this.dataApiService.postLogin(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post register Based on user parameter.
   * @param bodyobject object of key value;
   * @returns Observable of Category page.
   */
  postRegister(bodyobject: any): any {
    return this.dataApiService.postRegister(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */
  postForgotpassword(bodyobject: any): any {
    return this.dataApiService.postForgotpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */
  postResetpassword(bodyobject: any): any {
    return this.dataApiService.postResetpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */
  postSetnewpassword(bodyobject: any): any {
    return this.dataApiService.postSetnewpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkForgotPasswordLink(linkToken: any): any {
    return this.dataApiService.checkForgotPasswordLink(linkToken).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
    * Method to set user credential into local starage and service.
    * @param res response from api;
    * @param isstaylogin options for logged in data store into local storage;
    */
  setUserCredentials(res: any, isstaylogin: boolean, passEncode?: any) {
    this.user = res.firstname + ' ' + res.lastname;
    this.userfirstname = res.firstname;
    this.userlastname = res.lastname;
    this.userId = res.id;
    this.isLoggedin = 'true';
    this.auth = res.token;
    this.username = res.username;
    this.customer_id = res.customer_id;
    this.buchheitToken = res.buchheit_token;
    this.customerorder_id = localStorage.getItem('customerorder_id') ? localStorage.getItem('customerorder_id') : null;
    this.phone = res.userphonenumber;

    localStorage.setItem('user', this.user);
    localStorage.setItem('userfirstname', this.userfirstname);
    localStorage.setItem('userlastname', this.userlastname);
    localStorage.setItem('userId', this.userId);
    localStorage.setItem('isLoggedin', this.isLoggedin);
    localStorage.setItem('username', this.username);
    localStorage.setItem('customer_id', this.customer_id);
    localStorage.setItem('customerorder_id', this.customerorder_id);
    localStorage.setItem('phone', this.phone);
    localStorage.setItem('auth', this.auth);
    localStorage.setItem('isLoggedin', this.isLoggedin);
    localStorage.setItem('buchheit_token', this.buchheitToken);
    localStorage.setItem('lastAction', JSON.stringify(Date.now()));

    if (isstaylogin) {
      let rememberObj: any = { Email: this.username, Password: passEncode }
      localStorage.setItem('rememberAuth', JSON.stringify(rememberObj));
    } else {
      localStorage.removeItem('rememberAuth');
    }
    // window.sessionStorage.setItem('user', this.user);
    // window.sessionStorage.setItem('userfirstname', this.userfirstname);
    // window.sessionStorage.setItem('userlastname', this.userlastname);
    // window.sessionStorage.setItem('userId', this.userId);
    // window.sessionStorage.setItem('isLoggedin', this.isLoggedin);
    // window.sessionStorage.setItem('auth', this.auth);
    // window.sessionStorage.setItem('username', this.username);
    // window.sessionStorage.setItem('customer_id', this.customer_id);
    // window.sessionStorage.setItem('customerorder_id', this.customerorder_id);


    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, buchheit_token: this.buchheitToken }));

  }

  onsetUserProfileModification(res: any) {
    // console.log("callinggngngg",res)
    this.user = res.firstname + ' ' + res.lastname;
    this.userfirstname = res.firstname;
    this.username = res.username;
    this.userlastname = res.lastname;
    this.phone = res.phone;

    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, buchheit_token: this.buchheitToken }));

  }

  /**
  * Method to get user credential from local starage to  service.
  */
  getUserCredentials() {
    // const auth = window && window.sessionStorage ? window.sessionStorage.getItem('auth') : '';
    // console.log("auth", auth)
    if (localStorage.getItem('auth')) {
      this.user = localStorage.getItem('user');
      this.userId = localStorage.getItem('userId');
      this.isLoggedin = localStorage.getItem('isLoggedin');
      this.auth = localStorage.getItem('auth');
      this.username = localStorage.getItem('username');
      this.customer_id = localStorage.getItem('customer_id');
      this.customerorder_id = localStorage.getItem('customerorder_id');
      this.phone = localStorage.getItem('phone');
      this.username = localStorage.getItem('username');
      this.userfirstname = localStorage.getItem('userfirstname');
      this.userlastname = localStorage.getItem('userlastname');
      this.buchheitToken = localStorage.getItem('buchheit_token');
    }
    // else if (auth) {
    //   const user = window.sessionStorage.getItem('user');
    //   const userId = window.sessionStorage.getItem('userId');
    //   const isLoggedin = window.sessionStorage.getItem('isLoggedin');
    //   const username = window.sessionStorage.getItem('username');
    //   const customer_id = window.sessionStorage.getItem('customer_id');
    //   const customerorder_id = window.sessionStorage.getItem('customerorder_id');
    //   const userfirstname = window.sessionStorage.getItem('userfirstname');
    //   const userlastname = window.sessionStorage.getItem('userlastname');

    //   this.user = user;
    //   this.userId = userId;
    //   this.isLoggedin = isLoggedin;
    //   this.auth = auth;
    //   this.username = username;
    //   this.customer_id = customer_id;
    //   this.customerorder_id = customerorder_id;
    //   this.userfirstname = userfirstname;
    //   this.userlastname = userlastname;
    // }



    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, buchheit_token: this.buchheitToken  }));

  }
  /**
   * Method to remove user credential from local starage and change the value from service.
   */
  removeUserCredential() {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('auth');
    localStorage.removeItem('username');
    localStorage.removeItem('customer_id');
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('userfirstname');
    localStorage.removeItem('userlastname');
    localStorage.removeItem('currentAccountUrl');
    localStorage.removeItem('phone');
    localStorage.removeItem('buchheit_token');
    localStorage.removeItem('loginOnce');
    localStorage.removeItem('lastAction');

    // window.sessionStorage.removeItem('user');
    // window.sessionStorage.removeItem('userId');
    // window.sessionStorage.removeItem('isLoggedin');
    // window.sessionStorage.removeItem('auth');
    // window.sessionStorage.removeItem('username');
    // window.sessionStorage.removeItem('customer_id');
    // window.sessionStorage.removeItem('customerorder_id');
    // window.sessionStorage.removeItem('userfirstname');
    // window.sessionStorage.removeItem('userlastname');
    if(this.router.url.includes('/cart') || this.router.url.includes('/checkout') || this.router.url.includes('/user')){
      this.dataApiService.reloadCurrentRoute('/');
    }

    this.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: '', auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', buchheit_token: '' }));
    // this.commonService.setCartItems({ 'cartCount': 0, 'cartDetails': [] });
    this.user = '';
    this.userfirstname = '';
    this.userlastname = '';
    this.userId = '';
    this.isLoggedin = '';
    this.auth = '';
    this.username = '';
    this.customer_id = '';
    this.customerorder_id = '';
    this.phone = '';
    this.buchheitToken = '';
  }

  // ondefaultsetSessionstorage() {
  //   console.log("window",window)
  //   window.sessionStorage.setItem('user', '');
  //   window.sessionStorage.setItem('userfirstname', '');
  //   window.sessionStorage.setItem('userId', '');
  //   window.sessionStorage.setItem('isLoggedin', '');
  //   window.sessionStorage.setItem('auth', '');
  //   window.sessionStorage.setItem('username', '');
  //   window.sessionStorage.setItem('customer_id', '');
  // }
  // Sign in with Facebook
  onFacebookAuth() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider(), "facebook")
  }
  // Sign in with Google
  onGoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), 'google')
  }
  // Auth logic to run auth providers
  AuthLogin(providerinstance: any, provider: string) {
    return this.afAuth.signInWithPopup(providerinstance)
      .then((result: any) => {
        // console.log('You are successfully logged in!', result)
        return result;
      }).catch((error: any) => {
        // console.log(error);
        return error;
      })
  }

  AuthLogout() {
    return this.afAuth.signOut().then(() => {
      // console.log("successfully logout");
      //this.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: '', auth: '', username: '', customer_id: '', customerorder_id: '' }));
      this.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: '', auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', buchheit_token: '' }));
      // console.log(this.is)
      // this.dataApiService.reloadCurrentRoute();
    }).catch((error: any) => {
      // console.log("error logout", error);
    })

  }
  /**
   * Method to post my profile Based on user ID.
   * @param userid id of existing user;
   * @returns Observable of my profile response.
   */
  getmyprofile(userid: any): any {
    return this.dataApiService.getmyprofile(userid).pipe(
      map((data) => {
        return data;
      })
    );
  }
  putmyprofile(userid: any, bodyobject: any): any {
    return this.dataApiService.putmyprofile(userid, bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setWishList(payload: any) {
    this.wishlistData.next(payload);
  }

  setStoreWiseProductStock(payload: any) {
    this.storeWiseProductStock.next(payload);
  }

  setGlobalRouterLaoder(payload: any) {
    this.globalRouterLoaderShow.next(payload);
  }

}
