import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-facebook-login-ebates',
  templateUrl: './facebook-login-ebates.component.html',
  styleUrls: ['./facebook-login-ebates.component.scss']
})
export class FacebookLoginEbatesComponent implements OnInit {

  ebateEmail = new UntypedFormControl('', [Validators.required, Validators.email]);
  constructor(private dialogRef: MatDialogRef<FacebookLoginEbatesComponent>) { }

  ngOnInit(): void {
  }

  confirm(){
    this.ebateEmail.markAsTouched()
    if(this.ebateEmail.valid){
      this.dialogRef.close(this.ebateEmail.value)
    }
  }

}
