import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlynumberDirective } from './directives/onlynumber.directive';

@NgModule({
  declarations: [OnlynumberDirective],
  imports: [
    CommonModule
  ], exports: [
    OnlynumberDirective
  ]
})
export class DirectiveModule { }
