import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, OnInit, HostListener, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { NgxMasonryComponent } from 'ngx-masonry';
import { CommonService } from '../../../core/services/common.service';
import { UtilityService } from '../../../core/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingServiceService } from '@app/shared/service/listing-service.service';
import { NavService } from '@app/shared/service/nav.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isSticky: boolean = false;
  pSp = window.pageYOffset;
  cSp = window.pageYOffset;
  white = "rgba(255, 255, 255,0.12)";
  MegaMenuBox = false;
  MegaMenuLink = false;
  MegaMenuThird = false;
  isFirstTimeMegaMenuClicked = true;
  // isSticky: boolean = false;
  //  pSp = window.pageYOffset;
  //  cSp=window.pageYOffset;
  //  @HostListener('window:scroll', ['$event'])
  //  checkScroll() {
  //   this.cSp = window.pageYOffset;
  //   if(this.cSp > 20){
  //      this.isSticky = this.cSp < this.pSp;
  //    }else{
  //     this.isSticky = false
  //    }
  //    this.pSp = this.cSp;
  //  }
  // masronyIndex: number;
  timer: any;
  menu: any;
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  Clicked: boolean;
  public hoverMenu: any = [];
  mouseover(item: any, index: number) {
    this.timer = setTimeout(() => {
      if (this.masonry && !this.navMenuList[index].children && !this.navMenuList[index].childrenLoading) {
        this.navMenuList[index].childrenLoading = true;
        this.commonService.getTaxonomyLevelWiseNavigation(this.functionId, this.navMenuList[index].merchandisehierarchygroup_id).subscribe((value: any) => {
          this.navMenuList[index].children = value.data;
          this.navMenuList[index].children.forEach((subData: any) => {
            if (subData.haschild) {
              this.commonService.getTaxonomyLevelWiseNavigation(this.functionId, subData.merchandisehierarchygroup_id).subscribe((subDataResponse: any) => {
                subData.children = subDataResponse.data;
                if (this.masonry) {
                  this.masonry.reloadItems();
                  this.masonry.layout();
                }
              }, (error) => console.error(error));
            }
          });
        }, (error) => console.error(error));
      } else {
        if (this.masonry) {
          this.masonry.reloadItems();
          this.masonry.layout();
        }
      }
      setTimeout(() => {
        this.hoverMenu = item;
        this.menu = index;
      }, 400);
    }, 450);

  }

  mouseleave() {
    clearTimeout(this.timer);
  }



  isBrowser: boolean;
  public navMenuItem: any = [];
  public navMenuName = '';
  public navMenuList: any = [];
  public navItemImage = '';
  public navHeaderMenu: any = [];
  public navTaxonomy: any = [];
  public navigationTaxonomiesList: any = [];
  public taxonomyName: string = '';
  public functionId: any = 0;

  constructor(@Inject(PLATFORM_ID) readonly platformId: Object, readonly commonService: CommonService, readonly utilityService: UtilityService, public router: Router, public activatedRoute: ActivatedRoute, private listingService: ListingServiceService, private navService: NavService) {
    this.isBrowser = false;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    else if (isPlatformServer(this.platformId)) {
      this.isBrowser = false;
    }
    if (this.masonry) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
    if (this.isBrowser) {
      this.commonService.getNavigationItem().subscribe((value: any) => {
        if (value && value.length > 0 && value[0].data && value[0].data.length > 0) {
          this.navMenuName = value[0].navMenuName;
          this.functionId = value[0].nav_menu_id;
          if (this.activatedRoute?.snapshot?.routeConfig?.path === "listing") {
            this.commonService.activeMenu(this.functionId);
          }
          value[0].data.forEach((e: any, i: number) => {
            if (e.is_default) {
              this.navMenuItem = e;
              this.navHeaderMenu = e.navigation_item;
              this.navHeaderMenu = this.navHeaderMenu.filter((e: any) => { return e.is_top_link == false });
              this.navTaxonomy = e.navigation_taxonomy;
            }
          })
          this.navService.navMenu = value[0].data[0].navigation_item;
        }
      }, (error) => console.error(error));
    }


    this.activatedRoute.params.subscribe(params => {
      if (params.name) {
        this.menuShowList(false);
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.cSp = window.pageYOffset;
    if (this.cSp > 40) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  // isParentMenuOpened(index: number): boolean {
  //   return `/shop/${this.navMenuList[index].merchandisehierarchygroupslug}` === this.router.url;
  // }

  // isChildMenuOpened(index: number, data:any): boolean {
  //   return `/listing/${data.merchandisehierarchygroupslug}` === this.router.url;
  // }

  // checkParentSlugIsActiveOrNot(){
  //   let index = 0;
  //   let hoverMenu = -1;
  //   this.navMenuList.forEach((data:any) =>{
  //     if(`/shop/${data.merchandisehierarchygroupslug}` === this.router.url || `/listing/${data.merchandisehierarchygroupslug}` === this.router.url) {
  //       this.mouseover(data, index);
  //       hoverMenu = index;
  //     }
  //     index += 1;
  //     if(index=== this.navMenuList.length && hoverMenu === -1) {this.mouseover(this.navMenuList[0], 0);}
  //   });
  // }

  getParentTaxonomyNavigation() {
    if (!this.commonService.isUserAgentForSpeedTest()) {
      this.commonService.getTaxonomyLevelWiseNavigation(this.functionId).subscribe((value: any) => {
        //console.log(value);
        if (value && value.data && value.data.length > 0) {
          this.navMenuList = value.data;
          this.mouseover(this.navMenuList[0], 0);
          // this.checkParentSlugIsActiveOrNot();
          // this.mouseover(this.navMenuList[0], 0);
          // if (this.navTaxonomy.length > 0) {
          //   this.navTaxonomy.forEach((g: any, k: number) => {
          //     this.patchNodes(value.data, g);
          //   })
          // //   console.log(this.navMenuItem.function_id)
          //   value.data.forEach((t:any) => {
          //     if(t.merchandisehierarchygroup_id === this.navMenuItem.function_id){
          //       this.currentMenu = t;
          //     }
          //   });
          //   value.data = value.data.filter((x: any) => x.selected);
          //   this.navigationTaxonomiesList = value.data;
          //   this.navMenuName = this.currentMenu.merchandisehierarchygroupname;
          //   if (this.currentMenu.children && this.currentMenu.children.length > 0) {
          //     this.currentMenu.children.forEach((e: any, i: number) => {
          //       if (e.selected && e.children) {
          //         e.children = e.children.filter((x: any) => x.selected);
          //         e.children.forEach((f: any) => {
          //           if (f.children && f.children.length > 0) {
          //             f.children = f.children.filter((x: any) => x.selected);
          //             f.children.forEach((g: any) => {
          //               if (g.children && g.children.length > 0) {
          //                 g.children = g.children.filter((x: any) => x.selected);
          //               }
          //             })
          //           }
          //         })
          //         this.navMenuList.push(e);
          //         this.menu = 0;
          //         this.hoverMenu = this.navMenuList[0];
          //       }
          //     })
          //   }
          // }
        }
      }, (err: any) => {
        if (err) {
          // console.log(err)
        }
      });
    }
  }

  public currentMenu: any = [];
  menuShowList(click: boolean) {
    if (this.isFirstTimeMegaMenuClicked) {
      this.getParentTaxonomyNavigation();

    }
    this.isFirstTimeMegaMenuClicked = false;
    this.MegaMenuBox = true;
    if (!click) {
      this.activatedRoute.params.subscribe(params => {
        // console.log(params)
        if (params.name) {
          this.taxonomyName = params.name;
          this.MegaMenuBox = false;
        }
      });
    }
  }

  patchNodes(array: any, id: number): void {
    array.forEach((node: any) => {
      if (node.merchandisehierarchygroup_id === id) {
        node.selected = true;
      }
      if (node.children && node.children.length > 0) {
        this.patchNodes(node.children, id);
      }
      if (node.merchandisehierarchygroup_id === id && !node.children) {
        node.children = [];
        node.selected = true;
      }
    });
  }

  getImageUrl(imageName: string): string {
    return this.utilityService.getImage(imageName, 'taxonomy');
  }

  ngAfterViewInit(): void {
    if (this.masonry) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  goCategoryPage(event: any, item: any) {
    event.preventDefault();
    this.router.navigate([`shop/`, item.merchandisehierarchygroupslug])
    this.MegaMenuBox = false;
    // this.checkParentSlugIsActiveOrNot();
  }
  goListingPage(event: any, item: any) {
    event.preventDefault();
    const slug = item.merchandisehierarchygroup_id;
    this.router.navigateByUrl(`/search?category=${slug}`);
    this.MegaMenuBox = false;
    setTimeout(()=> window.location.reload(),200);
    // this.commonService.activeMenu(this.functionId);
    // this.checkParentSlugIsActiveOrNot();
  }

  isSelectedHeader(menu: any) {
    return ((this.router.url !== '/') && (this.router.url === `/${menu.navigationitem_value}` || this.router.url === `/${menu.navigationitem_slug}` || this.router.url.startsWith(`/${menu.navigationitem_slug}?`) || this.router.url.startsWith(`/${menu.navigationitem_value}?`)
      || this.router.url === `/shop/${menu.navigationitem_value}` || this.router.url === `/shop/${menu.navigationitem_slug}` || this.router.url.startsWith(`/shop/${menu.navigationitem_value}?`) || this.router.url.startsWith(`/shop/${menu.navigationitem_slug}?`)
      || this.router.url === `/p/${menu.navigationitem_value}` || this.router.url === `/p/${menu.navigationitem_slug}` || this.router.url.startsWith(`/p/${menu.navigationitem_value}?`) || this.router.url.startsWith(`/p/${menu.navigationitem_slug}?`)
      || this.router.url === `/item-group/${menu.navigationitem_value}` || this.router.url === `/item-group/${menu.navigationitem_slug}` || this.router.url.startsWith(`/item-group/${menu.navigationitem_value}?`) || this.router.url.startsWith(`/item-group/${menu.navigationitem_slug}?`)
      || this.router.url === `/listing/${menu.navigationitem_label.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")}` || this.router.url.startsWith(`/listing/${menu.navigationitem_label.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")} ?`)))
  }
  isGiftcardPage(data: any) {
    if (this.router.url == `/${data}`) return true;
  }

  menuClick(menu: any) {
    let slug_or_id: any = menu?.navigationitem_slug ? menu?.navigationitem_slug : menu?.navigationitem_value;
    if (menu.navigationitemtype.toString() === '5') {
      this.commonService.getNavigationURL().subscribe((value: any) => {
        if (value && value.data && value.data.length > 0) {
          value.data.forEach((e: any) => {
            if (e.navigationitemurl_id === menu.navigationitem_value) {
              window.open(e.navigation_url, '_self')
            }
          })
        }
      }, (error) => console.error(error));
    } else if (menu.navigationitemtype.toString() === '4') {
      this.commonService.activeMenu(this.functionId);
      this.router.navigate([`/listing/${slug_or_id}`]);
      //this.router.navigate([`/listing/${menu.navigationitem_label.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")}`]);
    } else if (menu.navigationitemtype.toString() === '2') {
      this.router.navigateByUrl('/shop', { skipLocationChange: true }).then(() => {
        this.router.navigate([`/${slug_or_id}`]);
      });
    } else if (menu.navigationitemtype.toString() === '3') {
      this.router.navigate([`/p/${slug_or_id}`]);
    } else if (menu.navigationitemtype.toString() === '1') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['item-group', slug_or_id]);
      });
    }
  }
}
