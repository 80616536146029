import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorService } from '@app/core/services/interceptor.service';
import { DataResolverService } from './shared/service/data-resolver.service';
import { environment } from 'environments/environment';
import { LayoutModule } from "./misc/layout/layout.module";
import { AlertModule } from '@app/alert/alert.module';
import { MiscMaterialModule } from '@app/misc/misc-material/misc-material.module';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { MetaService2 } from './core/services/meta.service';
import { BrowserStateInterceptor } from './core/services/http.interceptor.browser';
import { HawkSearchListingComponent } from './hawk-search-listing/hawk-search-listing.component';
import { CurrencyPipe } from '@angular/common';
import { HawksearchEventTrackingService } from './core/services/hawksearch-event-tracking.service';
// import { GiftcardModule } from './giftcard/giftcard.module';
@NgModule({
    declarations: [
        AppComponent,
        HawkSearchListingComponent
    ],
    imports: [
        MatSnackBarModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HttpClientModule,
        GalleryModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseAccess),
        AlertModule,
        MiscMaterialModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        // 	// enabled: environment.production,
        // 	registrationStrategy: 'registerImmediately'

        // }),
        // GiftcardModule
    ],
    exports: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, DataResolverService, GoogleAnalyticsService, MetaService2, CurrencyPipe, HawksearchEventTrackingService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
