import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { SeodataResolver } from './seodata.resolver';
import { AuthguardGuard } from './core/guard/authguard.guard';
import { SeoManageResolver } from './seo-manage.resolver';
import { CustomUrlSerializer } from './custom-url-serializer';
import { PageNotFoundComponent } from './misc/layout/page-not-found/page-not-found.component';
import { HawkSearchListingComponent } from './hawk-search-listing/hawk-search-listing.component';

const routes: Routes = [
  {
    path: 'circular',
    loadChildren: () => import('./flipp/flipp.module').then(p => p.FlippModule),
  },
  {
    path: 'search',
    component: HawkSearchListingComponent
  },
  { path: 'booking', loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule) },
  {
    path: 'guest-user',
    loadChildren: () =>
      import('./guest-checkout/guestcheckout.module').then((p) => p.GuestcheckoutModule),
  },
  // {
  //   path: 'login',
  //   canActivate: [AuthguardGuard],
  //   loadChildren: () => import('./publish/publish.module').then((m) => m.PublishModule)
  // },
  // {
  //   path: 'register',
  //   canActivate: [AuthguardGuard],
  //   loadChildren: () => import('./publish/publish.module').then((m) => m.PublishModule)
  // },
  {
    path: 'shop',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    resolve: { data: SeoManageResolver },
    data: { resolvedata: 'categoryPage' },
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./category-page/category-page.module').then(
        (p) => p.CategoryPageModule
      ),
    /*},
  ],*/
  },
  {
    path: 'user',
    // component: LayoutComponent,
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./user/user.module').then((p) => p.UserModule),
    /*},
  ],*/
  },
  {
    path: 'listing',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import('./listing/listing.module').then((p) => p.ListingModule),
  },
  {
    path: 'p/:id',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./details/details.module').then((p) => p.DetailsModule),
    /*},
  ],*/
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then((p) => p.CartModule),
    canActivate: [AuthguardGuard],
  },
  {
    path: 'checkout',
    canActivate: [AuthguardGuard],
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./checkout/checkout.module').then((p) => p.CheckoutModule),
    /*},
  ],*/
    // loadChildren: () => import('./checkout/checkout.module').then((p) => p.CheckoutModule),
  },
  {
    path: 'giftcards',
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./giftcard/giftcard.module').then(p => p.GiftcardModule)
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent
  },
  {
    path: ':slug',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    resolve: { data: SeodataResolver },
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./publish/publish.module').then((m) => m.PublishModule),
    /*},
  ],*/
  },
  {
    path: 'item-group',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import('./item-group/item-group.module').then((m) => m.ItemGroupModule),
  },
  {
    path: '',
    // component: LayoutComponent,
    canActivate: [AuthguardGuard],
    resolve: { data: SeodataResolver },
    /*children: [
      {
        path: '', pathMatch: 'full',*/
    loadChildren: () =>
      import('./publish/publish.module').then((m) => m.PublishModule),
    /*},
  ],*/
  },
  {
    path: 'b/:id/:bookingId/:eventId/:storeId',
    // component: LayoutComponent,
    /*children: [
      {
        path: '',*/
    loadChildren: () =>
      import('./details/details.module').then((p) => p.DetailsModule),
    /*},
  ],*/
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
})
export class AppRoutingModule { }
//  {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', scrollOffset: [0, 64]}
