import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UtilityService } from '../../../../app/core/services/utility.service';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@app/misc/login/login/login.component';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { CommonService } from '@app/core/services/common.service';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformServer } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  imageWidth: any;
  imageHeight: any;
  environment: any = environment;

  public footerData: any = [];
  public footerDataJson : any = {};
  selectedOption: number;
  public isLoggedin : boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private utilityService: UtilityService,private renderer: Renderer2,public dialog: MatDialog,readonly dataApiService: DataApiService,public commonService: CommonService,private auth: AuthService,public router: Router, @Inject(DOCUMENT) private document: Document,) {
    this.imageWidth = 0;
    this.imageHeight = 0;
  }

  ngOnInit(): void {
    
    this.commonService.getNavFooter()?.subscribe((value:any) => {
      if(value){
        this.footerData = value[0].data[0];
        if(this.footerData.status == "Active"){
          this.footerDataJson = JSON.parse(this.footerData.footer_json);
        }
      }
    },(error)=> console.error(error))
    this.checkLogInState();
  }

  checkLogInState(){
    this.auth.authToken.subscribe((value:any) => {
      this.isLoggedin = value.isLoggedin === 'true' ? true : false;
    });
  }

  getLazyImage(imageName: string, status: boolean): string {
    this.imageWidth = 100;
    this.imageHeight = 100;
    return this.utilityService.getLazyImage(imageName, `seo_img`, status, `${this.imageWidth}x${this.imageHeight}`, 'inside');
  }
  getImageUrl(imageName: string, modulename:string, status: boolean): string {
    return this.utilityService.getLazyImage(imageName, modulename, status,`100x100`);
  }
  goOrderStatus(item:any){
    this.checkLogInState();
    if(this.isLoggedin){
      window.open(`${item.url}`, '_self');
    }else{
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['pop-up', 'md-pop']
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.dataApiService.reloadCurrentRoute();
        }
      });
    }
  }
  openSignUp(){
    this.checkLogInState();
    if(this.isLoggedin){
      this.router.navigate(['/user/yourprofile']);
    }else{
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: ['pop-up', 'md-pop'],
        data: {register:true}
      });

      dialogRef.afterClosed().subscribe((result) => {
        if(result){
          this.dataApiService.reloadCurrentRoute();
        }
      });
    }
  }
  customSecActive: boolean = false;
  aboutSecActive: boolean = false;
  resourceSecActive: boolean = false;
  connectSecActive: boolean = false;
  findSecActive: boolean = false;
  currentLink(sec:string){
    if(sec == 'custom'){
      this.aboutSecActive = false;
      this.resourceSecActive = false;
      this.connectSecActive = false;
      this.findSecActive = false;
      this.customSecActive = !this.customSecActive;
    }else if(sec == 'about'){
      this.customSecActive = false;
      this.resourceSecActive = false;
      this.connectSecActive = false;
      this.findSecActive = false;
      this.aboutSecActive = !this.aboutSecActive;
    }else if(sec == 'resource'){
      this.customSecActive = false;
      this.aboutSecActive = false;
      this.connectSecActive = false;
      this.findSecActive = false;
      this.resourceSecActive = !this.resourceSecActive;
    }else if(sec == 'connect'){
      this.customSecActive = false;
      this.aboutSecActive = false;
      this.resourceSecActive = false;
      this.findSecActive = false;
      this.connectSecActive = !this.connectSecActive;
    }else if(sec == 'find'){
      this.customSecActive = false;
      this.aboutSecActive = false;
      this.resourceSecActive = false;
      this.connectSecActive = false;
      this.findSecActive = !this.findSecActive;
    }
  }
  script2 = this.renderer.createElement('script') as HTMLScriptElement;

  // addfooterScript(){
  //   const scriptBody = this.renderer.createText(`(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
  //   vgo('setAccount', '27843790');
  //   vgo('setTrackByDefault', true);
  //   vgo('process');`);
  //   this.renderer.appendChild(this.script2, scriptBody);
  //   this.renderer.appendChild(this.document.head, this.script2);
  // }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    //this.renderer.removeChild(this.document.head, this.script2)
  }

}
