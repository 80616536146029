import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private popupOpen: boolean = false;

  isPopupOpen(): boolean {
    return this.popupOpen;
  }

  setPopupOpen(open: boolean): void {
    this.popupOpen = open;
  }
}
