import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { LoginComponent } from '../../login/login/login.component';
import { NavService } from '@app/shared/service/nav.service';
import { CommonService } from '../../../core/services/common.service';
import { UtilityService } from '../../../core/services/utility.service';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-mob-nav',
  templateUrl: './mob-nav.component.html',
  styleUrls: ['./mob-nav.component.scss']
})
export class MobNavComponent implements OnInit {
  MenuBox = false;
  MenuBoxOne = false;
  MenuBoxTwo = false;
  public navMenuItem: any = [];
  public navMenuName: string = '';
  public navMenuList: any = [];
  public navItemImage: string = '';
  public navHeaderMenu: any = [];
  public firstLevelMenu: any = [];
  public secondLevelMenu: any = [];
  public thirdLevelMenu: any = [];
  public functionId: any = 0;
  isloggedin!: boolean;
  firstName:string='';
   userid:string='';
   usershortname:string='';
  userName:string='';

  constructor( @Inject(PLATFORM_ID) private platformId: Object,readonly commonService: CommonService,private auth: AuthService,private cdRef: ChangeDetectorRef, readonly utilityService: UtilityService, private router: Router, public activatedRoute: ActivatedRoute, private dialog: MatDialog, private dataApiService: DataApiService, private navService: NavService) { }

  ngOnInit(): void {
    
    if(!this.commonService.isUserAgentForSpeedTest()){
      this.commonService.getNavigationItem()?.subscribe((value: any) => {
        if (value && value.length && value[0].data && value[0].data.length > 0) {
          this.navMenuName = value[0].navMenuName;
          this.functionId = value[0].nav_menu_id;
          if(this.activatedRoute?.snapshot?.routeConfig?.path === "listing"){
            this.commonService.activeMenu(this.functionId);
        }
          value[0].data.forEach((e: any, i: number) => {
            if (e.is_default) {
              this.navMenuItem = e;
              this.navHeaderMenu = e.navigation_item;
            }
          })
          this.navService.navMenu = value[0].data[0].navigation_item;
        }
      },(error)=> console.error(error));
    }
  }

  getLoginsubscriptiondata() {
    const usersubscription = this.auth.authToken.subscribe((value) => {
      if (value) {
        this.isloggedin = value.isLoggedin === 'true' ? true : false;
        this.firstName = value.userfirstname?.length > 10 ? value.userfirstname.toString().substr(0, 10) + '...' : value.userfirstname;
        this.userName = value.username;
      } else {
        this.isloggedin = false;
      }
    });
  }

  registerOrLogin(){
    this.web_nav()
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: ['pop-up','md-pop','login-pop']
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        this.dataApiService.reloadCurrentRoute();
      }
    });
  }

  gotoProfile(){
    this.web_nav()
    this.router.navigate(['/user/youraccount']);
  }

  getFirstName(){
    this.getLoginsubscriptiondata();
    return this.firstName;
  }

  getUserName(){
    this.getLoginsubscriptiondata();
    return this.userName;
  }

  isUserLoggedIn(){
    this.getLoginsubscriptiondata();
    return this.isloggedin;
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  getParentTaxonomyNavigation() {
    if (!this.navMenuList.length) {
      if (this.functionId) {
        this.commonService.getTaxonomyLevelWiseNavigation(this.functionId).subscribe((value: any) => {
            if (value && value.message === 'Success') {
              this.navMenuList = value.data;
            }
          },(error)=> console.error(error));
      } else {
        this.commonService.getNavigationItem().subscribe((value: any) => {
          if (value.length > 0 && value[0].data && value[0].data.length > 0) {
            this.functionId = value[0].nav_menu_id;
            if (this.functionId) {
              this.commonService.getTaxonomyLevelWiseNavigation(this.functionId).subscribe((value: any) => {
                  if (value && value.message === 'Success') {
                    this.navMenuList = value.data;
                  }
                });
            }
          }
        },(error)=> console.error(error));
      }
    }
  }

  getChildTaxonomy(item: any, index: number): any {
    if (!item.children && !item.childrenLoading) {
      item.childrenLoading = true;
      this.commonService.getTaxonomyLevelWiseNavigation(this.functionId, item.merchandisehierarchygroup_id).subscribe((value: any) => {
        item.children = value.data;
      },(error)=> console.error(error));
    }
  }

  patchNodes(array: any, id: number): void {
    array.forEach((node: any) => {
      if (node.merchandisehierarchygroup_id === id) {
        node.selected = true;
      }
      if (node.children && node.children.length > 0) {
        this.patchNodes(node.children, id);
      }
      if (node.merchandisehierarchygroup_id === id && !node.children) {
        node.children = [];
        node.selected = true;
      }
    });
  }
  getSecondLevel(item: any, index: number) {
    this.MenuBoxOne = true
    this.secondLevelMenu = item
    this.getChildTaxonomy(item, index);
  }
  getThirdLevel(item: any, index: number) {
    this.MenuBoxTwo = true;
    this.thirdLevelMenu = item;
    this.getChildTaxonomy(item, index);
  }

  web_nav() {
    const element = document.getElementById("web-nav");
    element.classList.toggle("show");
  }
  onSignout():void{
    this.auth.removeUserCredential();
    this.commonService.setCartItems({ 'cartCount': 0, 'cartDetails': [] });
    this.auth.AuthLogout();
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('cartDetailsStorage');
    localStorage.removeItem('is_stock');
    localStorage.removeItem('booking_flag');
    localStorage.removeItem('booking_store');
  }

  goCategoryPage(event: any, item: any) {
    event.preventDefault();
    this.router.navigate([`shop/`, item.merchandisehierarchygroupslug]);
  }
  goListingPage(event: any, item: any,list:any,taxonomy:any) {
    event.preventDefault();
    const slug = item.merchandisehierarchygroupslug;
    this.router.navigate([`/listing/${slug}`]);
  }

  menuClick(menu:any){
    let slug_or_id:any = menu?.navigationitem_slug ? menu?.navigationitem_slug : menu?.navigationitem_value;
    if(menu.navigationitemtype.toString() === '5'){
      this.commonService.getNavigationURL().subscribe((value:any) => {
        if(value && value.data && value.data.length > 0){
          value.data.forEach((e:any) => {
            if(e.navigationitemurl_id === menu.navigationitem_value){
                window.open(e.navigation_url, '_self')
            }
          })
        }
      },(error)=> console.error(error))
    }else if(menu.navigationitemtype.toString() === '4'){
      this.commonService.activeMenu(this.functionId);
      this.router.navigate([`/listing/${slug_or_id}`]);
    }else if(menu.navigationitemtype.toString() === '2'){
      this.router.navigate([`/`]);
      setTimeout(() => {
        this.router.navigate([`/${slug_or_id}`]);
      },200);
    }else if(menu.navigationitemtype.toString() === '3'){
      this.router.navigate([`/p/${slug_or_id}`]);
    }else if(menu.navigationitemtype.toString() === '1'){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['item-group', slug_or_id]);
      });
    }
  }

  getHeaderImages(imageName: string, moduleName: string, status: boolean, width?: number, height?: number){
    height = height || 100;
    width = width || 100;
    return this.utilityService.getLazyImage(imageName, moduleName, status, `${width}x${height}`);
  }

}
