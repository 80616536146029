import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { NgxMasonryModule } from 'ngx-masonry';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    NgxMasonryModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,
    NgSelectModule,
    MatTabsModule
  ], exports:[
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    NgxMasonryModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,
    NgSelectModule,
    MatTabsModule,
    MatBadgeModule
  ]
})
export class MiscMaterialModule { }
