import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HawksearchEventTrackingService {


  private apiUrl = `${environment.trackingUrl}/api/trackevent`;
  private clientGuid = environment.ClientGuid;

  // Storage keys
  private hawksearchQueryIdName = 'QueryId';
  private hawksearchVisitIdName = 'VisitId';
  private hawksearchVisitorIdName = 'VisitorId';

  constructor(private http: HttpClient) { }

  // Clear Visit Id - Call this function after an order has been placed
  clearHawksearchVisitId() {
    sessionStorage.removeItem(this.hawksearchVisitIdName);
  }

  // Retrieve HawkSearch Query Id
  getHawkSearchQueryId() {
    return localStorage.getItem(this.hawksearchQueryIdName);
  }

  // Retrieve HawkSearch Visit Id
  getHawksearchVisitId() {
    let visitId = sessionStorage.getItem(this.hawksearchVisitIdName);
    if (!visitId) {
      visitId = this.createHawksearchUUID();
      sessionStorage.setItem(this.hawksearchVisitIdName, visitId);
    }
    return visitId;
  }

  // Retrieve HawkSearch Visitor Id
  getHawksearchVisitorId() {
    let visitorId = localStorage.getItem(this.hawksearchVisitorIdName);
    if (!visitorId) {
      visitorId = this.createHawksearchUUID();
      localStorage.setItem(this.hawksearchVisitorIdName, visitorId);
    }
    return visitorId;
  }

  // Set HawkSearch Query Id
  setHawkSearchQueryId(value: string) {
    localStorage.setItem(this.hawksearchQueryIdName, value);
  }

  // Track Add to Cart Event
  trackAddToCartEvent(eventData: any) {
    this.trackEvent('Add2Cart', eventData);
  }

  // Track Sale Event
  trackSaleEvent(eventData: any) {
    this.trackEvent('Sale', eventData);
  }
  // Autocomplete Trending Items Click Event
  handleTrendingItemClick(eventData:any) {
    this.trackEvent('AutoCompleteTrendingItemsClick', eventData);
  }

   // Autocomplete Trending Category Click Event
   handleTrendingCategoryClick(eventData:any) {
    this.trackEvent('AutoCompleteTrendingCategoryClick', eventData);
  }

  // General method to track any event
  private trackEvent(eventType: string, eventData: any) {
    const serializedEventData = JSON.stringify(eventData);
    const encodedEventData = btoa(serializedEventData);

    const requestBody = {
      ClientGuid: this.clientGuid,
      VisitId: this.getHawksearchVisitId(),
      VisitorId: this.getHawksearchVisitorId(),
      EventType: eventType,
      EventData: encodedEventData,
    };

    this.http.post(this.apiUrl, requestBody).subscribe();
  }

  // Helper function to generate a UUID
  private createHawksearchUUID(): string {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
