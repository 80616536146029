<div class="cart-add">
  <ul class="cart-listing">
    <ng-container *ngFor="let itemCart of getCartItemArray; let i=index;">
      <li *ngIf="itemCart">
        <div class="card">
          <div class="card-image" title="{{itemCart?.Item?.name}}"
          [routerLink]="[ProductDetailsPageUrl(itemCart?.Item)]" (click)="cartBoxClose();">
            <div class="card-image-box">
              <img [defaultImage]="getImageUrl(commonService.getProductDefaultImage(itemCart,true,itemCart.positemqualifier)?.image, true, 200, 200,commonService.getProductDefaultImage(itemCart,true,itemCart.positemqualifier)?.image_timestamp)" [lazyLoad]="getImageUrl(commonService.getProductDefaultImage(itemCart,true,itemCart.positemqualifier)?.image, false, 100, 100,commonService.getProductDefaultImage(itemCart,true,itemCart.positemqualifier)?.image_timestamp)" alt="{{commonService.getProductDefaultImage(itemCart,true,itemCart.positemqualifier)?.alt_tag}}" width="100" height="100" (error)="commonService.getNotFoundImage($event,100,100)">
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="card-brand">{{itemCart?.Item?.brand_name}}</div>
              <div class="card-name" matTooltip="">
                <a [routerLink]="[ProductDetailsPageUrl(itemCart?.Item)]" (click)="cartBoxClose();">{{itemCart?.Item?.name}}</a>
              </div>
              <div class="card-brand">{{itemCart.positemqualifierName}}</div>
            </div>
            <div class="card-desc">SKU : {{itemCart?.Item?.sku}}</div>
            <div class="card-price-box">
              <div class="card-price">
                <div class="card-price-main">
                  ${{itemCart.offeredperunitprice*itemCart.quantity | number : '1.2-2'}}
                </div>
                <ng-container *ngIf="itemCart.offeredperunitprice < itemCart.perunitprice">
                  <div class="card-price-del">${{itemCart.perunitprice*itemCart.quantity | number : '1.2-2'}}</div>
                </ng-container>
                <div class="card-price-offer" *ngIf="itemCart.offer > 0">
                  <mat-icon aria-label="icon" class="material-icons-outlined">check_circle</mat-icon>
                  2 offers applied
                </div>
                <div class="card-price-offer" [ngStyle]="{'color':'#d1232a'}" *ngIf="!itemCart.hasOwnProperty('isAddonOf') && itemCart.maximumsaleunitcount > 0 && itemCart.quantity > itemCart.maximumsaleunitcount">
                  <!-- Maximun {{itemCart.maximumsaleunitcount}} item(s) will allow -->
                  Limit {{itemCart.maximumsaleunitcount}} Per Household
                </div>
                <div class="card-price-offer" [ngStyle]="{'color':'#d1232a'}" *ngIf="!itemCart.ignore_stock && !itemCart.hasOwnProperty('isAddonOf') && itemCart.quantity > (itemCart?.rest_stock)">
                  Out of stock
                </div>
              </div>
              <div class="card-button-group" *ngIf="!itemCart.hasOwnProperty('isAddonOf')">
                <button aria-label="button" aria-label="" mat-stroked-button color="primary" class="box-btn sm-btn fill-btn" (click)="commonService.decreaseCount(itemCart,bookingPayload);itemCart.quantity = (itemCart.quantity === 0 ? 1 : itemCart.quantity);manageRapidUiQty(itemCart);">
                  <mat-icon aria-label="icon">remove</mat-icon>
                </button>
                <mat-form-field appearance="outline" class="no-label text-center">
                  <input matInput [(ngModel)]="itemCart.quantity" type="number" min="1" max="100" value="{{ itemCart.quantity }}" (keypress)="commonService.checkNumberValidation($event)" (change)="commonService.manageCount(itemCart.quantity, itemCart,'',bookingPayload)">
                </mat-form-field>
                <button aria-label="button" aria-label="" mat-stroked-button color="primary" class="box-btn sm-btn fill-btn" (click)="commonService.increaseCount(itemCart,bookingPayload);manageRapidUiQty(itemCart);"  [disabled]="(!itemCart?.ignore_stock && itemCart.quantity >= itemCart?.rest_stock || (itemCart.maximumsaleunitcount > 0 && itemCart.quantity >= itemCart.maximumsaleunitcount) || itemCart.totalprice===0)">
                  <mat-icon aria-label="icon">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="cart-add-btn">
    <button aria-label="button" aria-label="" mat-flat-button color="accent" (click)="placeOrder()" class="btn-block">Proceed to Buy</button>
  </div>
</div>
