<div mat-dialog-title>
  <div class="row align-items-center justify-content-between no-gutters">
    <div class="col-auto" ><span *ngIf="data.from !== 'order-details-popup' && data.from !== 'bookingError'">{{displayTitle}}</span></div>
    <div class="col-auto" *ngIf="data.from == 'order-details-popup' || data.from == 'bookingError'">
      <button mat-icon-button mat-dialog-close class="sm-btn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="tableId !== 'myebate'">
  <div mat-dialog-content *ngIf="data.from !== 'order-details-popup' && data.from !== 'bookingError'">
    <p>{{ displayMsg }}</p>
  </div>

  <div mat-dialog-content *ngIf="data.from === 'bookingError'">
    <h3>{{ displayMsg }}</h3>
  </div>

  <div mat-dialog-content *ngIf="data.from === 'order-details-popup'" style="font-size: large; text-align: center;">
    <div>This item is not available online.</div>
    <div>Please contact the store for more information.</div>
  </div>

  <div mat-dialog-actions *ngIf="data.from !== 'order-details-popup'">
    <button aria-label="button" mat-flat-button color="accent" cdkFocusInitial class="sm-btn" (click)="deleteRecord()">{{ confirmButtonText }}</button>

    <button *ngIf="data.from !== 'bookingError'" aria-label="button" mat-stroked-button color="accent" class="sm-btn" (click)="cancelBtnClicked(cancelButtonText)">{{ cancelButtonText }}</button>
  </div>
</ng-container>


<ng-container *ngIf="tableId=== 'myebate'">

  <div mat-dialog-content class="">

    <div class="image-gallery">
      <img [src]="getImageUrl(image, true)" alt="images">
    </div>
  </div>
</ng-container>