import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  /**
   * Method to intercent http requests.
   * @param req Request parameter;
   * @param next HttpHandler parameter.
   * @returns Observable of HttpEvent.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL is the Hawksearch tracking API
    if (req.url.includes('api/trackevent')) {
      // Simply pass the request through without any modifications
      return next.handle(req);
    }
    if (!req.url.startsWith(window.location.origin)) {
      if (!req.url.includes('selfIPaddress') && !req.url.includes('services') && req.url !== 'http://api.ipify.org/?format=json') {
        const url = `${environment.apiCustomRootUrl}`;
        req = req.clone({
          url: url + req.url
        });

        const token: string = this.auth.auth;
        if (token && (req.url.includes('rebatecentervalidate') || req.url.includes('giftcardorders') || req.url.includes('customergiftcard') || req.url.includes('consumerchangepassword') || req.url.includes('consumerprofile') || req.url.includes('recentvieweditem') || (req.url.includes('productreviewsratings') && req.method === 'POST') || req.url.includes('mywishlist') || req.url.includes('productreviewsratingsbycustomer') || req.url.includes('productreviewsratingsupdate') || req.url.includes('/cart') || req.url.includes('customerwishlist') || req.url.includes('customeraddresses') || req.url.includes('customerorderlist') || req.url.includes('stripepayment') || req.url.includes('orderpickupauthorize') || req.url.includes('cuponvalidation') || req.url.includes('customerbillingaddressordersummary') || req.url.includes('stripecardlist') || req.url.includes('billingaddresssave') || req.url.includes('checkpaymentmethod') || req.url.includes('customerstockcheck') || req.url.includes('giftcardpaymentcapture') || req.url.includes('checkout') || req.url.includes('customerrebate') || req.url.includes('rebatecenter') || req.url.includes('uiorderstatelookup') || req.url.includes('pickupslotcheck') || req.url.includes('customernightlyemail') || req.url.includes('rebatelist') || req.url.includes('rebateuifetch'))) {
          req = req.clone({ headers: req.headers.set('Authorization', 'Token ' + token) });
        }
      } else if (req.url.includes('services')) {
        const buchheitToken: string = this.auth.buchheitToken;

        const url = `${environment.apiRebate}`;
        req = req.clone({
          url: url + req.url
        });
        if (buchheitToken && (req.url.includes('validate') && req.method === 'POST') || (req.url.includes('big-list') && req.method === 'POST') || (req.url.includes('rebate-balance') && req.method === 'POST')) {
          req = req.clone({ headers: req.headers.set('access-token', buchheitToken) });
        } else if (req.url.includes('online/order') && req.method === 'POST' || req.url.includes('online/order-lines') && req.method === 'POST') {
          const token: string = this.auth.auth;
          req = req.clone({ headers: req.headers.set('Authorization', 'Token ' + token) });
        }
      }
      else {
        const url = `${environment.apiCustomIpAddress}`;
        req = req.clone({
          url: url
        });
      }
    }
    return next.handle(req);
    // return of(null);
  }
}


/*
if (token && (req.url.includes('consumerchangepassword')||req.url.includes('consumerprofile')||req.url.includes('recentvieweditem')||(req.url.includes('productreviewsratings') && req.method==='POST') || req.url.includes('mywishlist') || req.url.includes('productreviewsratingsbycustomer') || req.url.includes('productreviewsratingsupdate') || req.url.includes('/cart') || req.url.includes('customerwishlist') || req.url.includes('customeraddresses') || req.url.includes('customerorderlist') )) {
*/

