import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonService } from '@app/core/services/common.service';
import { MetaService2 } from './core/services/meta.service';


@Injectable({
  providedIn: 'root'
})
export class SeodataResolver implements Resolve<boolean> {
  cmsObject: any;
  constructor(readonly commonservice: CommonService, private router: Router,private metaService: MetaService2) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    let cmsPageSlug = route.params.slug || 'home';
    return this.commonservice
      .getSeoDetails('cms_slug', cmsPageSlug)
      .subscribe(
        (res: any) => {
          if (res) {
            this.cmsObject = res.data;
            const seoData: any = {};
            seoData['title'] = this.cmsObject.cms_page_title
              ? this.cmsObject.cms_page_title
              : this.cmsObject.default_seo?.page_title || '';
            seoData['description'] = this.cmsObject.cms_page_description
              || this.cmsObject.default_seo?.page_description || `${this.cmsObject.cms_page_title || this.cmsObject.default_seo?.page_title || ''}`;
            seoData['keywords'] = this.cmsObject.cms_page_keyword
              || this.cmsObject.default_seo?.page_keyword || `${this.cmsObject.cms_page_title || this.cmsObject.default_seo?.page_title || ''}`;
            seoData['meta_location'] = this.cmsObject.cms_meta_location
              ? this.cmsObject.cms_meta_location
              : this.cmsObject.default_seo?.meta_location || '';
            seoData['meta_robots'] = this.cmsObject.cms_meta_robots
              ? this.cmsObject.cms_meta_robots
              : this.cmsObject.default_seo?.meta_robots || '';
            seoData['meta_referral'] = this.cmsObject.cms_meta_referral
              ? this.cmsObject.cms_meta_referral
              : this.cmsObject.default_seo?.meta_referral || '';
            seoData['meta_rights'] = this.cmsObject.cms_meta_rights
              ? this.cmsObject.cms_meta_rights
              : this.cmsObject.default_seo?.meta_rights || '';
            seoData['og_type'] = this.cmsObject.cms_og_type
              ? this.cmsObject.cms_og_type
              : this.cmsObject.default_seo?.og_type || '';
            seoData['og_url'] = this.cmsObject.cms_og_url
              ? this.cmsObject.cms_og_url
              : this.cmsObject.default_seo?.og_url || '';
            seoData['og_title'] = this.cmsObject.cms_og_title
              ? this.cmsObject.cms_og_title
              : this.cmsObject.default_seo?.og_title || `${this.cmsObject.cms_page_title || this.cmsObject.default_seo?.page_title || ''}`;
            seoData['og_description'] = this.cmsObject.cms_og_description
              || this.cmsObject.default_seo?.og_description || `${this.cmsObject.cms_page_title || this.cmsObject.default_seo?.page_title || ''}`;
            seoData['feature_image'] = '';
            seoData['og_local'] = this.cmsObject.cms_og_local
              ? this.cmsObject.cms_og_local
              : this.cmsObject.default_seo?.og_local || '';
            seoData['twitter_card'] = this.cmsObject.cms_twitter_card
              ? this.cmsObject.cms_twitter_card
              : this.cmsObject.default_seo?.twitter_card || '';
            seoData['cms_script'] = this.cmsObject.cms_script
              ? this.cmsObject.cms_script
              : this.cmsObject.default_seo?.script || '';
            this.commonservice.setAdMetaData(seoData, this.router.url);
          }
        }, (err: any) => {
        }
      );
    //this.commonservice.setAdMetaData(seoData);
  }
}
