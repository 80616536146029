import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<any>{

  constructor(private commonService: CommonService) { }
  resolve(route: ActivatedRouteSnapshot) {
    // return this.commonService.getTaxonomyNavigation();
  }
}
