<!-- <section class="Buchheit-banner">
	<div class="animated-background"></div>
</section>
<section class="Buchheit-catagories">
	<div class="container-xl">
		<ul class="Buchheit-catagories-wrapper">
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
			<li class="Buchheit-catagories-box">
				<div class="Buchheit-catagories-img">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-catagories-title">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</li>
		</ul>
	</div>
</section>
<section class="Buchheit-image-text">
	<div class="container-xl">
		<div class="Buchheit-image-text-wrapper">
			<div class="Buchheit-image-text-box">
				<div class="Buchheit-image-text-img">
					<div class="Buchheit-image-text-img-image">
						<div class="animated-background"></div>
					</div>

				</div>
				<div class="Buchheit-image-text-title">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-image-text-des">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</div>
			<div class="Buchheit-image-text-box">
				<div class="Buchheit-image-text-img">
					<div class="Buchheit-image-text-img-image">
						<div class="animated-background"></div>
					</div>

				</div>
				<div class="Buchheit-image-text-title">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-image-text-des">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</div>
			<div class="Buchheit-image-text-box">
				<div class="Buchheit-image-text-img">
					<div class="Buchheit-image-text-img-image">
						<div class="animated-background"></div>
					</div>

				</div>
				<div class="Buchheit-image-text-title">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-image-text-des">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</div>
			<div class="Buchheit-image-text-box">
				<div class="Buchheit-image-text-img">
					<div class="Buchheit-image-text-img-image">
						<div class="animated-background"></div>
					</div>

				</div>
				<div class="Buchheit-image-text-title">
					<div class="animated-background"></div>
				</div>
				<div class="Buchheit-image-text-des">
					<div class="animated-background"></div>
					<div class="animated-background"></div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<div class="pageLoader">
	<div class="pageLoader-box">
		<svg class="lds-typing"   viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
			<circle cx="24.5" cy="48.4263" r="6" >
			  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s"></animate>
			</circle>
			<circle cx="41.5" cy="62.5" r="6" >
			  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.375s"></animate>
			</circle> <circle cx="58.5" cy="62.5" r="6" >
			  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s"></animate>
			</circle> <circle cx="75.5" cy="62.5" r="6" >
			  <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="62.5;37.5;62.5;62.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.125s"></animate>
			</circle>
		</svg>
	</div>
</div>
