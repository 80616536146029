<div id="appHeader">
  <app-header (changeMegaMenu)="changeMegaMenu()" *ngIf="!isYourAccountPage()" (getNavigation)="getNavigation()"></app-header>
</div>
<app-nav id="appNav" *ngIf="DesktopNav && !isCheckoutPage() && !isGiftcardCheckoutPage() && !isGuestCheckoutPage()"></app-nav>
<app-mob-nav *ngIf="MobileNav && !isCheckoutPage() && !isGiftcardCheckoutPage() && !isGuestCheckoutPage()"></app-mob-nav>
<!-- <app-body-loader *ngIf="isLoaderVisible() && !isDetailsPage() && !isItemGroupPage()"></app-body-loader> -->
<app-body-loader *ngIf="commonService.isUserAgentForSpeedTest()"></app-body-loader>
<router-outlet *ngIf="!commonService.isUserAgentForSpeedTest()"></router-outlet>
<div id="appFooter">
  <app-footer *ngIf="canShowFooter"></app-footer>
</div>
