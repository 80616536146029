import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MiscMaterialModule } from '../misc-material/misc-material.module';

import { LoginComponent } from './login/login.component';
import { DirectiveModule } from '@app/directive/directive.module';
import { FacebookLoginEbatesComponent } from './facebook-login-ebates/facebook-login-ebates.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [LoginComponent, FacebookLoginEbatesComponent],
  imports: [CommonModule, MiscMaterialModule, RouterModule, DirectiveModule, LazyLoadImageModule],
  exports: [LoginComponent],
})
export class LoginModule {}
