import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '@app/core/services/popup.service';
import { UtilityService } from '@app/core/services/utility.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit,OnDestroy {

  displayMsg: string;
  displayTitle: string;
  confirmButtonText:string;
  cancelButtonText:string;
  tableId: string;
  image: string;
  closeBtn:boolean;
  cancelBtn:boolean;

  constructor( @Inject(MAT_DIALOG_DATA) public data: { displayTitle?: string, displayMsg?: string,closeBtn?:boolean, confirmButtonText?: string,cancelButtonText?:string, tableId?: string, image?: string , from?:string ,cancelBtn?:boolean}, public utilityService: UtilityService,
  private dialogRef: MatDialogRef<AlertModalComponent>,private popupService: PopupService) {
    this.displayTitle = this.data.displayTitle ? this.data.displayTitle : 'Delete Confirmation';
    this.displayMsg = this.data.displayMsg ? this.data.displayMsg : 'Are you sure you want to delete this Item?';
    this.confirmButtonText =  this.data.confirmButtonText ? this.data.confirmButtonText : 'Confirm';
    this.cancelButtonText =  this.data.cancelButtonText ? this.data.cancelButtonText : 'Cancel';
    this.tableId = this.data.tableId;
    this.image = this.data.image;
    this.closeBtn = this.data.closeBtn;
    this.cancelBtn = this.data.cancelBtn;
  }
  ngOnDestroy(): void {
    this.popupService.setPopupOpen(false);
  }

  ngOnInit(): void {
    this.popupService.setPopupOpen(true);
  }

  closeDialog(status?: string): void {
    this.dialogRef.close(status);
  }

  deleteRecord(): void {
      this.closeDialog('success');
  }


  getImageUrl(imageName: string, status: boolean): string {
    return this.utilityService.getLazyImage(imageName, `assets`, status, `757x958`);
  }
  cancelBtnClicked(text:string) {
    this.closeDialog(text);
  }

}
