import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  NavigationEnd
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonService } from '@app/core/services/common.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { MetaService2 } from './core/services/meta.service';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SeoManageResolver implements Resolve<boolean> {
  cmsObject: any;
  keyValue: string = '';
  constructor(readonly commonservice: CommonService, private router: Router, private googleAnalytics: GoogleAnalyticsService, private metaService: MetaService2) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let categoryPageSlug = state.url.split('/')[2] || '';
    categoryPageSlug.includes('?') && (categoryPageSlug = categoryPageSlug.split('?')[0] || '');
    let titleSeo = '';
    let storeId = localStorage.getItem('storeId');
    let myParam = route.data['resolvedata'];

    switch (myParam) {
      case 'categoryPage':
        this.keyValue = 'taxonomy_slug';
        break;
      case 'listingPage':
        this.keyValue = 'taxonomy_slug';
        break;
      case 'cartPage':
        titleSeo = 'Shopping Cart | Buchheits';
        break;
      case 'orderPage':
        titleSeo = 'Buchheits: Your Order History';
        break;
      case 'profilePage':
        titleSeo = 'My Profile';
        break;
      case 'rebatePage':
        titleSeo = 'Buchheits: Your ebates';
        break;
      case 'wishlistPage':
        titleSeo = 'Buchheits: Your Wishlist';
        break;
      case 'addressPage':
        titleSeo = 'Manage Address';
        break;
    }

    if (titleSeo == '') {

      return this.commonservice
        .getSeoDetails(this.keyValue, categoryPageSlug, storeId)
        .subscribe(
          (res: any) => {
            if (res?.data) {
              this.cmsObject = res.data;
              const seoData: any = {};
              if (myParam == 'categoryPage') {
                seoData['title'] = this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' at ' + res.store_name;
                seoData['description'] = this.cmsObject.metadescription ? this.capitalizeFirstLetter(this.cmsObject.metadescription) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' at ' + res.store_name;
                seoData['keywords'] = this.cmsObject.metakeyword ? this.capitalizeFirstLetter(this.cmsObject.metakeyword) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' at ' + res.store_name;
                seoData['og_title'] = this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' at ' + res.store_name;
                seoData['og_description'] = this.cmsObject.metadescription ? this.capitalizeFirstLetter(this.cmsObject.metadescription) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' at ' + res.store_name;
                seoData['feature_image'] = '';
              } else {
                seoData['title'] = this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' - Buy ' + this.cmsObject.metatitle + ' at ' + res.store_name;
                seoData['description'] = this.cmsObject.metadescription ? this.capitalizeFirstLetter(this.cmsObject.metadescription) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' - Buy ' + this.cmsObject.metatitle + ' at ' + res.store_name;
                seoData['keywords'] = this.cmsObject.metakeyword ? this.capitalizeFirstLetter(this.cmsObject.metakeyword) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' - Buy ' + this.cmsObject.metatitle + ' at ' + res.store_name;
                seoData['og_title'] = this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' - Buy ' + this.cmsObject.metatitle + ' at ' + res.store_name;
                seoData['og_description'] = this.cmsObject.metadescription ? this.capitalizeFirstLetter(this.cmsObject.metadescription) : this.capitalizeFirstLetter(this.cmsObject.metatitle) + ' - Buy ' + this.cmsObject.metatitle + ' at ' + res.store_name;
                seoData['feature_image'] = '';
              }

              seoData['meta_location'] = this.cmsObject.global_cms_seo.meta_location;
              seoData['meta_referral'] = this.cmsObject.global_cms_seo.meta_referral;
              seoData['meta_rights'] = this.cmsObject.global_cms_seo.meta_rights;
              seoData['meta_robots'] = this.cmsObject.global_cms_seo.meta_robots;
              seoData['og_image'] = this.cmsObject.global_cms_seo.og_image;
              seoData['og_local'] = this.cmsObject.global_cms_seo.og_local;
              seoData['og_type'] = this.cmsObject.global_cms_seo.og_type;
              seoData['og_url'] = this.cmsObject.global_cms_seo.og_url;
              seoData['twitter_card'] = this.cmsObject.global_cms_seo.twitter_card;
              seoData['view_point'] = this.cmsObject.global_cms_seo.view_point;
              this.commonservice.setAdMetaData(seoData, this.router.url);
              this.googleAnalytics.sendPageview(this.router.url, seoData.title);

            }
            /**This is the code for canonical url being injected to the ssr */
            if (!environment.isDevServer) {
          
              this.router.events.subscribe((event:any)=> {
                if(event instanceof NavigationEnd) {
                  const canonicalUrl = 'https://www.buchheits.com' + this.router.url;
                  const callCanonical = canonicalUrl.includes('.js') || canonicalUrl.includes('.png') || canonicalUrl.includes('.ico') || canonicalUrl.includes('.css') || this.doesAnyUrlMatch(this.urls, canonicalUrl);
              
                  if (!callCanonical && canonicalUrl !== 'https://www.buchheits.com/') {
                    this.metaService.removeTags(); // Moved inside the condition
                    this.metaService.addTag({ rel: 'canonical', href: canonicalUrl });
                  }
                }
              })
            }
          }, (err: any) => {
          }
        );

    } else {

      const seoData: any = {};
      seoData['title'] = titleSeo;
      seoData['description'] = titleSeo;
      seoData['keywords'] = titleSeo;
      seoData['og_title'] = titleSeo;
      this.commonservice.setAdMetaData(seoData, this.router.url);
      this.metaService.removeTags();
    }

    return of(true);
  }


  capitalizeFirstLetter(val: string) {
    return val ? val.charAt(0).toUpperCase() + val.slice(1) : '';
  }


  urls: any[] = [
    'https://www.buchheits.com/cart',
    'https://www.buchheits.com/shop',
    'https://www.buchheits.com/not-found',
    'https://www.buchheits.com/checkout/order-review',
    'https://www.buchheits.com/checkout/checkout-billing',
    'https://www.buchheits.com/checkout/order-complete',
    'https://www.buchheits.com/user/yourprofile',
    'https://www.buchheits.com/user/changepassword',
    'https://www.buchheits.com/user/yourorder',
    'https://www.buchheits.com/user/orderhistory',
    'https://www.buchheits.com/user/yourwishlist',
    'https://www.buchheits.com/user/youraddress',
    'https://www.buchheits.com/user/yourrebates',
    'https://www.buchheits.com/user/yourgiftcards'
  ];

  doesAnyUrlMatch(urls: string[], searchString: string): boolean {
    return urls.some(url => url === searchString);
  }
  
}
