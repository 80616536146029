<nav class="Buchheit-nav" id="web-nav">
	<div class="Buchheit-nav-header">
		<button aria-label="button" mat-icon-button (click)="web_nav()">
			<mat-icon aria-label="icon">close</mat-icon>
		</button>
		<div class="Buchheit-nav-logo" [routerLink]="['/']">
			<img [defaultImage]="getHeaderImages('logo.png', 'assets/images',  true, 296, 60)" [lazyLoad]="getHeaderImages('logo.png', 'assets/images', false, 296, 60)" alt="Logo" width="100" height="100">
		</div>
	</div>
	<div class="Buchheit-nav-user">
		<div class="row align-items-center" (click)="isUserLoggedIn() ? gotoProfile() : registerOrLogin()">
			<div class="col-auto">
				<mat-icon aria-label="icon" class="material-icons-outlined">account_circle</mat-icon>
			</div>
			<div class="col">
				{{isUserLoggedIn() ? firstName : 'Sign In/Register'}}
				<span>{{isUserLoggedIn() ? userName : ''}}</span>
			</div>
			<div class="col-auto">
				<mat-icon aria-label="icon">chevron_right
				</mat-icon>
			</div>
		</div>
	</div>
	<ul class="Buchheit-nav-menu">
		<li matRipple>
			<a (click)="MenuBox = true; getParentTaxonomyNavigation()"> {{navMenuName}}
				<mat-icon aria-label="icon" class="ml-auto text-accent mr-0">chevron_right</mat-icon>
			</a>
		</li>
		<li matRipple *ngFor="let menu of navHeaderMenu; let i=index">
			<a (click)="menuClick(menu); web_nav()"> {{menu.navigationitem_label}}</a>
		</li>
    <li *ngIf="isUserLoggedIn();" matRipple (click)="web_nav()"><a [routerLink]="'user/yourrebates'"> Your ebate</a></li>
    <!-- <li *ngIf="isUserLoggedIn();" matRipple (click)="web_nav()"><a [routerLink]="'user/yourprofile'"> Your Profile</a></li> -->
    <li *ngIf="isUserLoggedIn();" matRipple (click)="web_nav()"><a [routerLink]="'user/youraccount'"> Your Profile</a></li>
		<li *ngIf="isUserLoggedIn();" matRipple (click)="onSignout(); web_nav()"><a> Sign Out</a></li>
	</ul>
	<div class="Buchheit-nav-box" [ngClass]="MenuBox ? 'active' : ''">
		<div class="Buchheit-nav-heading">
			<button aria-label="button" mat-icon-button color="white" (click)="MenuBox = false" class="sm-btn">
				<mat-icon aria-label="icon" class="m-0">arrow_back_ios_new</mat-icon>
			</button>
			{{navMenuName}}
		</div>
		<ul class="Buchheit-nav-menu">
			<li matRipple *ngFor="let menu of navMenuList;let i=index">
				<a href="/shop/{{menu.merchandisehierarchygroupslug}}"
					(click)="goCategoryPage($event,menu); MenuBox = false; web_nav()">
					<mat-icon aria-label="icon">{{menu.icon || 'category'}}</mat-icon>
					{{menu.merchandisehierarchygroupname}}
				</a>
				<mat-icon aria-label="icon" (click)="getSecondLevel(menu,i)" *ngIf="menu.haschild"
					class="ml-auto text-accent mr-0">chevron_right</mat-icon>
			</li>
		</ul>
	</div>
	<div class="Buchheit-nav-box" [ngClass]="MenuBoxOne ? 'active' : ''">
		<div class="Buchheit-nav-heading">
			<button aria-label="button" mat-icon-button color="white" (click)="MenuBoxOne = false" class="sm-btn">
				<mat-icon aria-label="icon" class="m-0">arrow_back_ios_new</mat-icon>
			</button>
			{{secondLevelMenu.merchandisehierarchygroupname}}
		</div>
		<ul class="Buchheit-nav-menu">
			<li matRipple *ngFor="let subMenu of secondLevelMenu.children;let j=index">
				<a href="/listing/{{secondLevelMenu.merchandisehierarchygroupslug}}"
					(click)="goListingPage($event,subMenu); MenuBoxOne = false; MenuBox = false; web_nav()">
					{{subMenu.merchandisehierarchygroupname}}
				</a>
				<mat-icon aria-label="icon" (click)="getThirdLevel(subMenu,j)" *ngIf="subMenu.haschild"
					class="ml-auto text-accent mr-0">chevron_right</mat-icon>
			</li>
			<!-- <li matRipple *ngIf="secondLevelMenu && secondLevelMenu.children && secondLevelMenu	.children.length == 0">
				<div >No Item Availabel!</div>
			</li> -->
		</ul>
	</div>
	<div class="Buchheit-nav-box" [ngClass]="MenuBoxTwo ? 'active' : ''">
		<div class="Buchheit-nav-heading">
			<button aria-label="button" mat-icon-button color="white" (click)="MenuBoxTwo = false" class="sm-btn">
				<mat-icon aria-label="icon" class="m-0">arrow_back_ios_new</mat-icon>
			</button>
			{{thirdLevelMenu.merchandisehierarchygroupname}}
		</div>
		<ul class="Buchheit-nav-menu">
			<li matRipple *ngFor="let thirdItem of thirdLevelMenu.children;let k=index">
				<a href="/shop/{{thirdItem.merchandisehierarchygroupslug}}"
					(click)="goListingPage($event,thirdItem); MenuBoxTwo = false; MenuBoxOne = false; MenuBox = false; web_nav(); ">
					{{thirdItem.merchandisehierarchygroupname}}
				</a>
			</li>
		</ul>
	</div>
</nav>
<div class="Buchheit-nav-bg" (click)="web_nav();MenuBox = false;MenuBoxOne = false;MenuBoxTwo = false"></div>
