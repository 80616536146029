import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CartAddComponent } from '@app/cart-add/cart-add.component';
import { AuthService } from '@app/core/services/auth.service';
import { CommonService } from '@app/core/services/common.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { HawkSearchConfigService } from '@app/core/services/hawk-search-config-service.service';
import { UtilityService } from '@app/core/services/utility.service';
import { CartService } from '@app/shared/service/cart.service';
import { take } from 'rxjs/operators';
declare let window: any;

@Component({
  selector: 'app-hawk-search-listing',
  templateUrl: './hawk-search-listing.component.html',
  styleUrls: ['./hawk-search-listing.component.scss'],
  // encapsulation: ViewEncapsulation.ShadowDom,
})
export class HawkSearchListingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('searchResultsItem1', { static: true })
  searchResultsItem1: ElementRef;
  @Output() autocompleteRequestEvent = new EventEmitter<any>();
  @ViewChild('myComponent') myComponentRef: CartAddComponent;
  searchResults: any[] = [];
  turnOnView: boolean = false;
  isPlaceOrderDisabled: boolean;
  data = {
    8: {
      price: '8_house_springs_price',
      inventory: '8_house_springs_inventory',
    },
    7: {
      price: '7_jacksonville_price',
      inventory: '7_jacksonville_inventory',
    },
    6: {
      price: '6_herculaneum_price',
      inventory: '6_herculaneum_inventory',
    },
    5: {
      price: '5_greenville_price',
      inventory: '5_greenville_inventory',
    },
    4: {
      price: '4_jackson_price',
      inventory: '4_jackson_inventory',
    },
    3: {
      price: '3_centralia_price',
      inventory: '3_centralia_inventory',
    },
    32: {
      price: '32_north_platte_price',
      inventory: '32_north_platte_inventory',
    },
    31: {
      price: '31_beatrice_price',
      inventory: '31_beatrice_inventory',
    },
    30: {
      price: '30_murray_price',
      inventory: '30_murray_inventory',
    },
    2: {
      price: '2_perryville_price',
      inventory: '2_perryville_inventory',
    },
    29: {
      price: '29_pocahontas_price',
      inventory: '29_pocahontas_inventory',
    },
    27: {
      price: '27_paragould_price',
      inventory: '27_paragould_inventory',
    },
    26: {
      price: '26_jonesboro_price',
      inventory: '26_jonesboro_inventory',
    },
    25: {
      price: '25_columbia_north_price',
      inventory: '25_columbia_north_inventory',
    },
    24: {
      price: '24_columbia_south_price',
      inventory: '24_columbia_south_inventory',
    },
    23: {
      price: '23_fulton_price',
      inventory: '23_fulton_inventory',
    },
    22: {
      price: '22_kirksville_price',
      inventory: '22_kirksville_inventory',
    },
    21: {
      price: '21_blue_springs_price',
      inventory: '21_blue_springs_inventory',
    },
    20: {
      price: '20_jefferson_city_price',
      inventory: '20_jefferson_city_inventory',
    },
    1: {
      price: '1_sparta_price',
      inventory: '1_sparta_inventory',
    },
  };
  productBadgeData: any = {};

  constructor(
    private hawkSearchService: HawkSearchConfigService,
    private cart: CartService,
    private router: Router,
    private commonService: CommonService,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe,
    private auth: AuthService,
    private elementRef: ElementRef,
    private as: GoogleAnalyticsService,
    private ngZone: NgZone,
    private utilityService: UtilityService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    let stockInitialized = false;
    addEventListener('hawksearch:before-search-executed', (event: any) => {
      const searchRequest = event.detail;

      const storeId = localStorage.getItem('storeId');
      const locationCode = this.data[storeId].inventory;
      let locationName = localStorage.getItem('storeName');

      if (!stockInitialized) {
        let locationInventory = locationCode;

        if (!searchRequest.FacetSelections) {
          searchRequest.FacetSelections = {};
        }

        searchRequest.FacetSelections[locationInventory] = ['1'];
        stockInitialized = true;
      }

      searchRequest.ClientData = {
        ...searchRequest.ClientData,
        Custom: { custom: locationName },
      };

     /* setTimeout(() => {
        this.addFacetToUrl(searchRequest);
      }, 700); */ // Commenting this as it is merged incorrectly
    });
  }

  addFacetToUrl(searchRequest: any) {
    const url = new URL(window.location.href);

    if (!searchRequest.FacetSelections) {
      const storeId = localStorage.getItem('storeId');
      if (storeId && this.data[storeId]) {
        const locationCode = this.data[storeId].inventory;
        if (locationCode) {
          // Remove any existing _inventory parameter from the URL
          // const inventoryParams = [...url.searchParams.keys()].filter(key => key.startsWith('_inventory'));
          // inventoryParams.forEach(param => url.searchParams.delete(param));

          url.searchParams.set(locationCode, '1');

          // Update the URL and reload the page if necessary
          window.history.replaceState(null, null, url.toString());
          window.location.reload();
        } else {
          console.error('No inventory code found for storeId:', storeId);
        }
      } else {
        console.error('Invalid storeId or missing data for store:', storeId);
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      delete (window as any).storeBox;
      delete (window as any).toggleHeart;
      delete (window as any).cartBox;
      delete (window as any).addToCart;
      let element: HTMLElement = document.getElementById('Cart-Box');
      element.classList.remove('active');
    }
  }

  ngOnInit(): void {
    /** nothing to worry about it */
  }

  ngAfterViewInit(): void {
    if (this.hawkSearchService.hawkSearch) {
      addEventListener('hawksearch:after-search-executed', (event: any) => {
        window.scrollTo(0, 0);
        const searchResponse = event.detail;
        if (searchResponse?.Results?.length) {
          this.processSearchResponse(searchResponse);
        }

        this.bindGlobalFunctions();
        this.registerHandlebarsHelpers();
        setTimeout(() => {
          this.turnOnView = true;
          this.getWishlistPatched();
          this.patchRatings(searchResponse);
          /** this.patchQuantity(); */
          this.handleItemPromotionsAndQualifier(searchResponse);
          this.closeSearchInputElement();
          this.processBadgeData(searchResponse);
        }, 200);
      });
    } else {
      // Handle the case where hawkSearch is not yet available
    }
  }

  closeSearchInputElement() {
    const searchInput = document.querySelector('.Buchheit-header-search');
    searchInput.classList.remove('active');
  }
  registerHandlebarsHelpers(): void {
    const hawkSearch = this.hawkSearchService.hawkSearch;
    this.hawkSearchService.hawkSearch.handlebars.registerHelper(
      'getInventory',
      (item) => {
        let inventoryLevel: any = 0;
        const storeId = localStorage.getItem('storeId');
        const storeKey = this.data[storeId].inventory;
        if (item.attributes[storeKey]) {
          inventoryLevel = item.attributes[storeKey];
        }
        return parseInt(inventoryLevel);
      }
    );

    this.hawkSearchService.hawkSearch.handlebars.registerHelper(
      'getPrice',
      (item) => {
        let price = 0;
        const storeId = localStorage.getItem('storeId');
        const storeKey = this.data[storeId].price;

        if (item.attributes[storeKey]) {
          price = item.attributes[storeKey];
        }
        return hawkSearch.handlebars.helpers.currency(price);
      }
    );

    this.hawkSearchService.hawkSearch.handlebars.registerHelper(
      'getQualifier',
      (item) => {
        return JSON.parse(item.attributes?.qualifier[0])?.qualifier_name;
      }
    );

    hawkSearch.handlebars.registerHelper('navigateToDetails', (item) => {
      if (item && item !== 'undefined' && item.attributes.item_slug[0]) {
        return `/p/${item.attributes.item_slug[0]}`;
      } else {
        return '';
      }
    });

    hawkSearch.handlebars.registerHelper('getStoreName', function (item) {
      let storeName = '';
      storeName = localStorage.getItem('storeName');
      return storeName;
    });
  }
  bindGlobalFunctions(): void {
    (window as any).storeBox = this.storeBox.bind(this);
    (window as any).toggleHeart = this.toggleHeart.bind(this);
    (window as any).addToCart = this.addToCart.bind(this);
    (window as any).handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick = (url: string, id: any) => {
    // Perform your custom actions here
    const matchedItem =
      this.hawkSearchService.hawkSearch.searchResponse.results.find(
        (ele) => ele.id === id
      );
    this.as.productClick(matchedItem);

    // you can navigate to the URL here
    this.ngZone.run(() => {
      this.router.navigate([`${url}`]);
    });
  };

  processSearchResponse(searchResponse: any) {
    searchResponse.Results.forEach((result) => {
      if (
        result.Document.image &&
        !result.Document.image[0]?.includes('https://images.buchheits.com/')
      ) {
        result.Document.image[0] = `https://images.buchheits.com/items//fit-in/200x200/${
          result.Document.image[0] || 'default-item-image.png'
        }`;
      }
    });
  }

  handleItemPromotionsAndQualifier(
    searchResponse: any,
    itemPromotionHandled?: boolean
  ) {
    if (!searchResponse?.Results?.length) {
      return;
    }

    const newPayload = this.generatePayload(searchResponse.Results);
    const finalPayload = this.generateFinalPayload(newPayload);

    this.commonService
      .itemPromotionHandler(finalPayload)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.updateItemPromotions(response, searchResponse);
        this.updateItemQualifier(response);
      });
  }

  generatePayload(results: any[]) {
    const newPayload = {};
    results?.forEach((result) => {
      const { item_id, brandname, category } = result.Document;
      const brand = brandname ? brandname[0] : null;
      const taxonomy = category?.map((cat) => parseInt(cat)) || [];

      newPayload[item_id] = {
        brand: brand,
        subbrand: null,
        taxonomy: taxonomy,
      };
    });
    return newPayload;
  }

  generateFinalPayload(newPayload: any) {
    return {
      items: newPayload,
      store: +localStorage.getItem('storeId'),
    };
  }

  updateItemPromotions(response: any, searchResponse: any) {
    const nodeList: any = document
      .querySelector('hawksearch-search-results')
      ?.shadowRoot.querySelector('hawksearch-search-results-list')
      ?.shadowRoot.querySelectorAll('hawksearch-search-results-item');

    searchResponse.Results?.forEach((element: any, index: number) => {
      const doc = response[element.DocId];
      const salePrice = doc?.offer_price;
      element.offer_price = salePrice;
      const iMapPrice = element?.Document?.imap[0];
      const priceTag = nodeList[index]?.shadowRoot.querySelector(
        '.search-results-list__item__price'
      );
      this.searchResults = searchResponse?.Results || [];

      if (
        iMapPrice &&
        (+salePrice || doc?.posidentity?.regular_price) < +iMapPrice
      ) {
        const innerHTML = `
          <div style="font-size: 1.375rem; font-weight: 800; color: #000;" class="text-for-price">
            View Price In Cart
          </div>
        `;
        this.renderer.setProperty(priceTag, 'innerHTML', innerHTML);

        const inStockSection =
          nodeList[index].shadowRoot.querySelector('#inStock-Section');
        if (inStockSection) {
          inStockSection.innerHTML = '';
        }

        return;
      }

      const storeId = localStorage.getItem('storeId');
      const storeKey = this.data[storeId].price;

      const priceFormatted = this.currencyPipe.transform(
        element.Document[storeKey]?.[0],
        'USD',
        'symbol',
        '1.2-2'
      );
      const salePriceFormatted = this.currencyPipe.transform(
        salePrice,
        'USD',
        'symbol',
        '1.2-2'
      );

      const innerHTML = salePrice
        ? `
        <span class="search-results-list__item__price__current" itemprop="price">${salePriceFormatted}</span>
        <span class="search-results-list__item__price__original sale-price">${priceFormatted}</span>
      `
        : `
        <span class="search-results-list__item__price__current" itemprop="price">${priceFormatted}</span>
      `;

      this.renderer.setProperty(priceTag, 'innerHTML', innerHTML);
      this.changePrice(response, nodeList[index]);
    });
    this.as.DoItForItems(searchResponse?.Results, 'rapidUiListing');
  }

  updateItemQualifier(response: any) {
    const searchResults = document.querySelector(
      'hawksearch-search-results'
    )?.shadowRoot;
    const resultsList = searchResults?.querySelector(
      'hawksearch-search-results-list'
    )?.shadowRoot;
    const nodeList = resultsList?.querySelectorAll(
      'hawksearch-search-results-item'
    );

    if (!nodeList) return;

    const storeId = localStorage.getItem('storeId');

    nodeList.forEach((element: any, index: number) => {
      if (!element?.data?.attributes?.selling_rules) return;

      let posidentityData = {};
      element.data.attributes.selling_rules.forEach((item) => {
        try {
          const parsedElement = JSON.parse(item);
          posidentityData[parsedElement.store_id] = parsedElement;
        } catch (e) {
          console.error('Error parsing item in selling_rules:', e);
        }
      });

      const posIdentity = posidentityData[storeId];
      this.patchPromotionButton(posIdentity, element, response);

      if (posIdentity?.not_to_show_online || posIdentity?.booking) {
        this.removeElementfromDom(element);
      }
    });
  }

  storeBox(itemId: any, qualifierData: any) {
    const { qualifier_id } = JSON.parse(qualifierData);
    let object = {
      Item: {
        id: +itemId,
      },
      positemqualifier: qualifier_id,
    };
    this.commonService.getStoreWiseStock(object);

    const element = document.getElementById('Store-Box');

    if (element) {
      element.classList.toggle('active');

      // Retry function
      const retry = (fn, retries, delay) => {
        return new Promise<void>((resolve, reject) => {
          const attempt = async (n) => {
            try {
              await fn();
              resolve();
            } catch (error) {
              if (n === 0) {
                reject(error);
              } else {
                setTimeout(() => attempt(n - 1), delay);
              }
            }
          };
          attempt(retries);
        });
      };

      // Function to check element visibility and dispatch event
      const checkAndDispatchEvent = () => {
        return new Promise<void>((resolve, reject) => {
          const element: any = document.getElementById('Store-Box');

          if (element) {
            const rect = element.getBoundingClientRect();
            const isVisible =
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <=
                (window.innerWidth || document.documentElement.clientWidth);
            const isEnabled = !element.disabled;

            if (isVisible && isEnabled) {
              const event = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
              });
              element.dispatchEvent(event);
              resolve();
            } else {
              if (!isVisible) {
                element.style.display = 'block';
              }
              if (!isEnabled) {
                element.disabled = false;
              }
              reject('Element is either not visible or not enabled');
            }
          } else {
            reject('Element not found');
          }
        });
      };

      // Retry the checkAndDispatchEvent function up to 4 times with a delay of 1000ms
      retry(checkAndDispatchEvent, 4, 1000)
        .then(() => {})
        .catch((error) => {
          console.error('Failed to click element:', error);
        });
    } else {
      console.error('Element not found.');
    }
  }

  toggleHeart(itemId: string, qualifierData: any) {
    const nodeList = this.elementRef.nativeElement
      .querySelector('hawksearch-search-results')
      .shadowRoot.querySelector('hawksearch-search-results-list')
      .shadowRoot.querySelectorAll('hawksearch-search-results-item');
    const { qualifier_id } = JSON.parse(qualifierData);
    for (let index = 0; index < nodeList.length; index++) {
      const element = nodeList[index];
      if (element.data.id == itemId) {
        const isWishlisted = this.wishListData?.includes(+itemId);
        const qualifier = qualifier_id;
        const storeId = localStorage.getItem('storeId');
        const storeKey = this.data[storeId].price;
        const doc = element.data?.attributes;
        let product = {
          Item: {
            id: +element.data.id,
            positemqualifier_id: qualifier,
            positemqualifier: qualifier,
            name: doc.name?.[0],
            brand: doc.brandname?.[0],
          },
          positemqualifier: qualifier,
          positemqualifier_id: qualifier,
          wishlist: element.data?.wishlist || false,
          isRapidUi: true,
          category_search: doc.category_search,
          booking: false,
          regularPrice: doc[storeKey]?.[0],
        };

        this.hawkSearchService.doFavorite(product, element, !isWishlisted);
      }
    }
  }

  elementFound = false;
  debounceTimeout: any;
  addToCart(itemId: string) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      const storeId = localStorage.getItem('storeId');
      const storeKey = this.data[storeId]?.price;

      // Find the matched item
      const matchedItem =
        this.hawkSearchService.hawkSearch.searchResponse.results.find(
          (ele) => ele.id === itemId
        );
      if (!matchedItem) return;

      const doc = matchedItem.attributes;
      const price = doc[storeKey]?.[0];

      // Find the search result item element
      const searchResults = this.elementRef.nativeElement
        .querySelector('hawksearch-search-results')
        .shadowRoot.querySelector('hawksearch-search-results-list')
        .shadowRoot.querySelectorAll('hawksearch-search-results-item');

      const matchedElement: any = Array.from(searchResults).find(
        (element: any) => element.data.id === itemId
      );

      if (!matchedElement) return;

      const increaseBtn: HTMLElement =
        matchedElement.shadowRoot.querySelector('#increase-btn');
      if (increaseBtn) {
        this.elementFound = true;
        return;
      }
      const priceTag = matchedElement?.shadowRoot.querySelector(
        '.search-results-list__item__price'
      );
      const currentPriceElement = priceTag.querySelector(
        '.search-results-list__item__price__current'
      );

      // Check if the element exists and get its text content
      let currentPrice;
      if (currentPriceElement) {
        currentPrice = currentPriceElement.textContent.trim();
        currentPrice = currentPrice.replace('$', '');
      }

      this.elementFound = false;

      // Add item to cart
      const { qualifier_id } = JSON.parse(doc.qualifier);
      this.addItemToCart(matchedItem, currentPrice || price, qualifier_id);

      this.cart.iscartSetObs.subscribe((data: any) => {
        const cartData = this.cart.getCartItemArray();
        if (cartData?.length) {
          if (!this.elementFound && this.router.url.includes('/search')) {
            this.cartBox();
          }
          this.updateSearchResultsWithCartData(searchResults, cartData, itemId);
        }
      });
    }, 300);
  }

  getSearchResults(): NodeListOf<any> {
    return this.elementRef.nativeElement
      .querySelector('hawksearch-search-results')
      .shadowRoot.querySelector('hawksearch-search-results-list')
      .shadowRoot.querySelectorAll('hawksearch-search-results-item');
  }

  addItemToCart(matchedItem: any, price: any, qualifier: any): void {
    const itemDetails = {
      Item: {
        id: +matchedItem.id,
        positemqualifier_id: qualifier,
        positemqualifier: qualifier,
        name: matchedItem.attributes?.name?.[0],
        brand: matchedItem.attributes?.brandname?.[0],
      },
      positemqualifier: qualifier,
      quantity: 1,
      itemCount: 1,
      positemqualifier_id: qualifier,
      regularPrice: price,
      isRapidUi: true,
      booking: false,
      category_search: matchedItem.attributes?.category_search,
    };

    this.commonService.updateCart(itemDetails);
  }

  updateSearchResultsWithCartData(
    searchResults: any,
    cartData: any[],
    itemId: string
  ): void {
    for (const element of searchResults) {
      const matchedItem = cartData?.find(
        (x: any) => +element.data.id === +x.item_id
      );
      if (matchedItem?.item_id && element.data.id === +itemId) {
        this.hawkSearchService.addToCart(itemId, element);
      }
    }
  }

  cartBox() {
    let element: HTMLElement = document.getElementById('Cart-Box');
    element?.classList.add('active');
  }

  wishListData: any = [];
  getWishlistPatched() {
    this.auth.wishlistData.subscribe((wishlistItems: any) => {
      this.wishListData = wishlistItems?.map((ele) => ele.id);
      const nodeList = this.elementRef.nativeElement
        .querySelector('hawksearch-search-results')
        ?.shadowRoot.querySelector('hawksearch-search-results-list')
        ?.shadowRoot.querySelectorAll('hawksearch-search-results-item');
      if (!nodeList.length) {
        return;
      }

      if (nodeList.length) {
        for (let index = 0; index < nodeList.length; index++) {
          const element = nodeList[index];
          wishlistItems?.find((data: any) => {
            if (element.data.id == data.id) {
              element.data.wishlist = true;
              this.hawkSearchService.toggleHeart(element, true);
            }
          });
        }
      }
    });
  }

  patchRatings(searchResponse) {
    const nodeList = document
      .querySelector('hawksearch-search-results')
      ?.shadowRoot.querySelector('hawksearch-search-results-list')
      ?.shadowRoot.querySelectorAll('hawksearch-search-results-item');

    searchResponse.Results?.forEach((element, index) => {
      const rating = Math.round(element?.Document.review_rating[0]);
      const node = nodeList[index].shadowRoot.querySelector(
        '.product-rating .ratings'
      );

      if (node) {
        // Clear previous ratings
        Array.from(node.children).forEach((span) =>
          span.removeAttribute('data-clicked')
        );

        for (let i = 1; i <= rating; i++) {
          node
            .querySelector(`span[data-rating="${i}"]`)
            .setAttribute('data-clicked', 'true');
        }

        const ratingText = node.querySelector('span[data-rating="6"]');
        ratingText.textContent = `(${element?.Document.review_count[0]})`;
      }
    });
  }
  callObsOnce = false;

  private retryCount = 0;
  private maxRetries = 5;

  patchQuantity() {
    try {
      const nodeList: any = this.elementRef.nativeElement
        .querySelector('hawksearch-search-results')
        .shadowRoot.querySelector('hawksearch-search-results-list')
        .shadowRoot.querySelectorAll('hawksearch-search-results-item');

      this.cart.cartObs$ = this.cart.iscartSetObs.subscribe((res: any) => {
        if (res?.length) {
          this.handleCartData(nodeList);
        }
      });
    } catch (error) {
      console.error('Error in patchQuantity:', error);
    }
  }

  handleCartData(nodeList: any): void {
    const cartData = this.cart.getCartItemArray();
    if (cartData.length) {
      this.processCartItems(cartData, nodeList);
      this.callObsOnce = true;
    } else {
      this.retryPatchQuantity();
    }
  }

  processCartItems(cartData: any[], nodeList: any): void {
    for (let i = 0; i < nodeList.length; i++) {
      const matchedItem = cartData.find(
        (x: any) => +nodeList[i].data.id === x.item_id
      );
      const maxCount =
        matchedItem?.maximumsaleunitcount > 0
          ? Math.min(matchedItem?.rest_stock, matchedItem?.maximumsaleunitcount)
          : matchedItem?.rest_stock;
      if (matchedItem?.item_id && matchedItem?.itemCount <= maxCount) {
        this.hawkSearchService.addToCart(matchedItem, nodeList[i]);
      }
    }
  }

  retryPatchQuantity(): void {
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
    }
  }

  patchPromotionButton(posIdentity: any, element: any, response: any) {
    const promotionButton =
      element.shadowRoot.querySelector('#promotion-button');
    if (promotionButton) {
      promotionButton.innerHTML = this.determineInnerHTML(
        posIdentity,
        element,
        false
      );
      setTimeout(() => {
        this.patchQuantity();
      }, 200);
    } else {
      const checkAvailability = element.shadowRoot.querySelector(
        '#check-availability'
      );
      checkAvailability.innerHTML = this.determineInnerHTML(
        posIdentity,
        element,
        true
      );
      const newInnerElement = checkAvailability.querySelector(
        '#check-availability'
      );
      if (newInnerElement) {
        newInnerElement.addEventListener('click', () => {
          window.storeBox(
            `${element.data?.id}`,
            `${element.data.attributes?.qualifier}`
          );
        });
      }
    }
    this.patchGroupItemPrice(response, element);
  }

  private determineInnerHTML(posidentity, element, isAvailable) {
    let visitStorePurchase =
      '<a class="product-button-availability">Visit Store For Purchase</a>';

    let inStoreOnly =
      '<a class="product-button-availability">Available In Store Only</a>';
    let isSpecialorder =
      '<a class="product-button-availability">Special Order Only</a>';

    let bothProductShow = `
    <a class="product-button-availability">Visit Store For Purchase</a> 
    <a class="product-button-availability">Available In Store Only</a>`;

    let addToCartButton = `<a class="product-button" id="add-to-cart-btn">Add To Cart</a>`;
    let checkAvailability = `<a class="product-button-availability" id="check-availability">Check
    Availablity</a>`;

    if (posidentity?.in_store_only && posidentity?.not_to_show_inventory) {
      this.removeCartEvent(element);
      this.removeStockSection(element);
      return bothProductShow;
    }
    if (posidentity?.in_store_only) {
      this.removeCartEvent(element);
      return inStoreOnly;
    }
    if (posidentity?.special_order_only) {
      this.removeCartEvent(element);
      this.removeStockSection(element);
      return isSpecialorder;
    }
    if (posidentity?.not_to_show_inventory) {
      this.removeStockSection(element);
      return visitStorePurchase;
    }
    if (isAvailable) return checkAvailability;
    return addToCartButton;
  }
  removeStockSection(element: any) {
    const inStockSection = element.shadowRoot.querySelector('#inStock-Section');
    this.removeCartEvent(element);
    if (inStockSection) inStockSection.innerHTML = '';
  }

  patchGroupItemPrice(response: any, element: any) {
    const addBtn = element.shadowRoot.querySelector('.addBtn');
    const elementId = element?.data?.id;
    const isSeeBuyingFlag = response[elementId]?.see_buying_flag || false;
    if (addBtn && isSeeBuyingFlag) {
      addBtn.removeAttribute('onclick');
      let SeeBuyingOption = `<a class="product-button" href="/p/${element?.data?.attributes?.item_slug}">See Buying Options</a>`;
      addBtn.innerHTML = SeeBuyingOption;
      this.removeStockSection(element);
    }
  }
  hasNonEmptyValues(obj) {
    return Object.values(obj).some(
      (value) => value !== null && value !== undefined
    );
  }
  changePrice(response: any, element: any) {
    const elementId = element?.data?.id;
    const productGroupItem = response[elementId]?.max_min_info || {};

    if (!this.hasNonEmptyValues(productGroupItem)) return;

    const priceBtn = element.shadowRoot.querySelector(
      '.search-results-list__item__price'
    );
    if (!priceBtn) return;

    let minPrice, maxPrice, minOfferPrice, maxOfferPrice, priceContent;

    if (
      productGroupItem?.max_info?.offer_price &&
      productGroupItem?.min_info?.offer_price
    ) {
      minPrice = productGroupItem.min_info.offer_price.original_price;
      maxPrice = productGroupItem?.max_info?.offer_price?.original_price;
      minOfferPrice = productGroupItem.min_info.offer_price?.offer_price;
      maxOfferPrice = productGroupItem?.max_info?.offer_price?.offer_price;

      priceContent = `
        <div class="card-price" style="display: flex;align-items: center;flex-wrap: wrap;">
          <div class="card-price-main" style="margin-inline-end: 0.625rem;">$${minOfferPrice}</div>
          <div class="card-price-del" style="margin-inline-end: 0.625rem;">$${minPrice}</div> -
          <div class="card-price-main" style="margin-inline-start: 0.625rem;">$${maxOfferPrice}</div>
          <div class="card-price-del" style="margin-inline-start: 0.625rem;">$${maxPrice}</div>
        </div>`;
    } else if (productGroupItem?.max_info?.offer_price) {
      minPrice = productGroupItem?.min_info?.item_regular_price?.price;
      maxPrice = productGroupItem?.max_info.offer_price?.original_price;
      maxOfferPrice = productGroupItem?.max_info?.offer_price?.offer_price;

      priceContent = `
        <div class="card-price" style="display: flex;align-items: center;flex-wrap: wrap;">
          <div class="card-price-main" style="margin-inline-end: 0.625rem;">$${minPrice}</div> -
          <div class="card-price-main" style="margin-inline-start: 0.625rem;">$${maxOfferPrice}</div>
          <div class="card-price-del" style="margin-inline-start: 0.625rem;">$${maxPrice}</div>
        </div>`;
    } else if (productGroupItem?.min_info?.offer_price) {
      maxPrice = productGroupItem?.max_info?.item_regular_price?.price;
      minPrice = productGroupItem?.min_info?.offer_price?.original_price;
      minOfferPrice = productGroupItem?.min_info?.offer_price?.offer_price;

      priceContent = `
        <div class="card-price" style="display: flex;align-items: center;flex-wrap: wrap;">
          <div class="card-price-main" style="margin-inline-start: 0.625rem;">$${minOfferPrice}</div>
          <div class="card-price-del" style="margin-inline-start: 0.625rem;">$${minPrice}</div> -
          <div class="card-price-main" style="margin-inline-start: 0.625rem;">$${maxPrice}</div>
        </div>`;
    } else {
      minPrice = productGroupItem?.min_info?.item_regular_price?.price;
      maxPrice = productGroupItem?.max_info?.item_regular_price?.price;

      priceContent = `
        <div class="card-price ng-star-inserted" style="display: flex;align-items: center;flex-wrap: wrap;">
          <div class="card-price-main" style="margin-inline-end: 0.625rem;">$${minPrice}</div> -
          <div class="card-price-main" style="margin-inline-start: 0.625rem;">$${maxPrice}</div>
        </div>`;
    }

    priceBtn.innerHTML = priceContent;
  }

  removeCartEvent(element: any) {
    const addBtn = element.shadowRoot.querySelector('.addBtn');
    addBtn.removeAttribute('onclick');
  }
  removeElementfromDom(element: any) {
    const parentDiv = element.closest('div');
    if (parentDiv) {
      parentDiv.remove();
    }
  }

  initializeAddToCartButton() {
    const nodeList: any = this.elementRef.nativeElement
      .querySelector('hawksearch-search-results')
      .shadowRoot.querySelector('hawksearch-search-results-list')
      .shadowRoot.querySelectorAll('hawksearch-search-results-item');

    for (const element of nodeList) {
      const addToCartBtn: HTMLElement =
        element.shadowRoot.querySelector('#add-to-cart-btn');
      if (addToCartBtn) {
        if (addToCartBtn.classList.contains('disabled')) {
          return;
        }
        // Disable the button to prevent multiple clicks
        addToCartBtn.classList.add('disabled');
      }
    }
  }
  processBadgeData(productData: any) {
    if (!productData?.Results?.length) {
      return;
    }
    const itemId = this.processIds(productData?.Results);
    this.commonService.getBadgeItems({ item_ids: [...itemId] }).subscribe(
      (response: any) => {
        this.productBadgeData = { ...this.productBadgeData, ...response };
        this.patchBadgeImage();
      },
      (error) => {
        console.error('Error fetching badge data:', error);
      }
    );
  }
  processIds(itemArray: any) {
    return itemArray.map((obj) => +obj?.DocId);
  }
  patchBadgeImage() {
    const nodeList = document
      .querySelector('hawksearch-search-results')
      ?.shadowRoot.querySelector('hawksearch-search-results-list')
      ?.shadowRoot.querySelectorAll('hawksearch-search-results-item');
    
    if (!nodeList) return;
  
    nodeList.forEach((element: any) => {
      const productId: number = +element?.data?.id;
      const badgeDiv = element.shadowRoot.querySelector('#badge-item');
  
      let innerHTML = '';
  
      const hasUpperLeft = this.checkGroupedDataAvailable('upper_left', productId);
      const hasLowerRight = this.checkGroupedDataAvailable('lower_right', productId);
  
      if (hasUpperLeft && hasLowerRight) {
        const upperImgUrl = this.getUpperLeftImage(productId);
        const lowerImgUrl = this.getLowerRightImage(productId);
        innerHTML = `
          <div class="upper_left">
            <img src="${upperImgUrl}" alt="Upper Left Badge" />
          </div>
          <div class="lower_right">
            <img src="${lowerImgUrl}" alt="Lower Right Badge" />
          </div>`;
      } else if (hasUpperLeft) {
        const upperImgUrl = this.getUpperLeftImage(productId);
        innerHTML = `
          <div class="upper_left">
            <img src="${upperImgUrl}" alt="Upper Left Badge" />
          </div>`;
      } else if (hasLowerRight) {
        const lowerImgUrl = this.getLowerRightImage(productId);
        innerHTML = `
          <div class="lower_right">
            <img src="${lowerImgUrl}" alt="Lower Right Badge" />
          </div>`;
      }
  
      if (innerHTML) {
        this.renderer.setProperty(badgeDiv, 'innerHTML', innerHTML);
      }
    });
  }
  
  sortByOrderBy(data) {
    if (!data) return [];
    return data.sort((a, b) => {
      const orderA = a.badge.orderby;
      const orderB = b.badge.orderby;
      if (orderA === null) return 1;
      if (orderB === null) return -1;
      return orderA - orderB;
    });
  }
  groupByImagePlacement(data: any) {
    if(!data) return [];
    return data.reduce((grouped: any, item: any) => {
      const placement = item.badge.imageplacement;

      if (placement === 'upper_left' || placement === 'lower_right') {
        grouped[placement] = grouped[placement] || [];
        grouped[placement].push(item);
      }

      return grouped;
    }, {});
  }
  getLowerRightImage(productId: number) {
    const groupImageData = this.groupByImagePlacement(this.productBadgeData[productId]);
    const sortedData = this.sortByOrderBy(groupImageData['lower_right']);
    return this.getImageFromData(sortedData);
  }

  getImageFromData(data) {
    if (!data || data.length === 0) return null;

    const { image_info } = data[0];
    if (image_info) {
      const { image, image_timestamp } = image_info;
      return this.utilityService.getLazyImage(
        image,
        'badges',
        false,
        `${60}x${60}`,
        image_timestamp
      );
    }
    return null;
  }

  getUpperLeftImage(productId: number): string {
    const groupImageData = this.groupByImagePlacement(this.productBadgeData[productId]);
    const sortedData = this.sortByOrderBy(groupImageData['upper_left']);
    return this.getImageFromData(sortedData);
  }
  checkGroupedDataAvailable(type: string , productId: number): boolean {
    const groupImageData = this.groupByImagePlacement(this.productBadgeData[productId]);
    return Array.isArray(groupImageData[type]) && groupImageData[type].length > 0;
  }
}
