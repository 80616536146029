<section class="module p404-module">
    <div class="container-xl text-center">
        <span class="p404-text">404</span>
        <h3 class="p404-heading">Page Not Found</h3>
        <p class="paragraph">Sorry, the page you are looking for doesn’t exist.</p>
        
        <div class="btn-gap text-center" >
           <button class="mat-flat-button mat-button-base mat-accent" size="lg"  (click)="navigateToHome()">GO TO HOME</button>
          </div>
    </div>
</section>